import { Component, Input, OnChanges, OnInit } from '@angular/core';

export class ProfileBubbleStates {
  static DANGER = 'DANGER';
  static PROCESSING = 'PROCESSING';
  static REMOTE = 'REMOTE';
}

export interface Profile {
  displayName?: string;
  lastName?: string;
  preferredName: string;
  profileImageUrl?: string;
}
export type ProfileBubbleSizes =
  | 'extra-small'
  | 'small'
  | 'medium-small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'extra-extra-large';
@Component({
  selector: 'om-profile-bubble',
  providers: [],
  templateUrl: './profile-bubble.component.html',
  styleUrls: ['./profile-bubble.component.scss'],
})
export class ProfileBubble implements OnChanges, OnInit {
  static COLOR_COMBINATIONS = 10;
  static DEFAULT_COLOR_COMBINATION = 1;

  @Input() colorCombo: number;
  @Input() profile: Profile;
  @Input() size: ProfileBubbleSizes = 'small';
  @Input() status: string;

  initials: string;
  profileBubbleStates = ProfileBubbleStates;
  source: string;

  constructor() {}

  ngOnInit() {
    if (this.colorCombo == null) {
      this.colorCombo = ProfileBubble.DEFAULT_COLOR_COMBINATION;
    }
    this.colorCombo = (this.colorCombo % ProfileBubble.COLOR_COMBINATIONS) + 1;
  }

  ngOnChanges() {
    if (this.profile == null) {
      return;
    }

    if (this.profile.profileImageUrl) {
      this.source = this.profile.profileImageUrl;
    } else {
      this.setDefaultProfileImage();
    }
  }

  private setDefaultProfileImage(): void {
    if (!!this.profile.preferredName) {
      this.initials = this.profile.preferredName[0];

      if (!!this.profile.lastName) {
        this.initials += this.profile.lastName[0];
      }
    } else if (!!this.profile.displayName) {
      this.initials = this.profile.displayName[0];
    }
  }

  fallbackToInitials(e: ErrorEvent) {
    this.source = null;
    this.setDefaultProfileImage();
  }

  profileImageStyles() {
    const styles = {
      'background-image': `url(${this.source})`,
    };
    return styles;
  }
}
