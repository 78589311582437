import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { from, fromEvent, Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ConfigService } from '@app/core/config.service';

import { UpdateUserProps, TrackMetricProps } from './interfaces';
import { anonymousLDUser, mapPropsToLDUser } from './launch-darkly-user-property-mapper';

/**
 *  Service wrapper around the LaunchDarkly Javascript SDK.
 * https://docs.launchdarkly.com/sdk/client-side/javascript
 */
@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private ldClient: LDClient.LDClient;
  user: LDUser;
  onChange$: Observable<any>;

  constructor(private config: ConfigService) {
    this.initializeLDClient();
  }

  /**
   * Call when you need percentage rollout or A/B testing for a feature flag targeting anonymous users.
   * Pass in your own random user key or use the UUID generated and returned by this function.
   *
   * @param key
   */
  setAnonymousUserUUID(key = uuidv4()): string {
    this.user.key = key;
    this.user.anonymous = true;
    this.identifyUser(this.user);
    return key;
  }

  /**
   * Returns the an observable of the value for a given flag based on the user identified for the LaunchDarkly client instance.
   * @param flag
   * @param defaultValue This value will be returned in the case that the LD client can't be initialized properly
   * @return Observable<any> Expect one of: boolean, number, string, or JSON
   */
  featureFlag$(flag: string, defaultValue?: any): Observable<any> {
    return from(this.ldClient.waitForInitialization()).pipe(
      map(() => this.ldClient.variation(flag, defaultValue)),
      catchError(error => {
        const errorPayload = 'Unable to initialize the LaunchDarkly SDK. Flagged features will be disabled';
        console.error(errorPayload);
        return observableOf(defaultValue);
      }),
    );
  }

  /**
   * Takes User and maps various properties to the LDUser interface then updates LaunchDarkly with the user property data
   * @param user
   * @param customAttributes
   * @param privateAttributes
   */
  updateUser(props: UpdateUserProps) {
    this.user = mapPropsToLDUser(props);
    this.identifyUser(this.user);
  }

  resetUser() {
    this.user = anonymousLDUser();
    this.identifyUser(this.user);
  }

  /**
   * Track custom events that map to Metrics configured in LaunchDarkly which are typically linked to one or more
   * feature flags for experimentation
   * @param props TrackMetricProps
   */
  track(props: TrackMetricProps) {
    this.ldClient.track(props.key, props.data, props.metricValue);
  }

  private initializeLDClient() {
    this.user = anonymousLDUser();
    this.ldClient = LDClient.initialize(this.config.json.launchDarklyClientId, this.user, {
      sendEventsOnlyForVariation: true,
    });
    if (window['Cypress']) {
      // Dont sign up for the EventSource stream of feature flag changes if this is a cypress test
      // As of yet we haven't figured out how to stub these EventSource streams, so we're just disabling it for cypress for now
    } else {
      this.onChange$ = fromEvent(this.ldClient, 'change');
    }
  }

  private identifyUser(user: LDUser) {
    this.ldClient.identify(user);
  }
}
