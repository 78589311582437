import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-email-verification-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './email-verification-step.component.html',
})
export class EmailVerificationStepComponent extends RegistrationStepDirective {
  @Input() preferredEmail;
  @Input() errorMessage;
  @Output() resendEmail = new EventEmitter<any>();
  @Output() emailTechSupport = new EventEmitter<any>();

  onSubmit() {
    this.submitting = true;
    this.submit.emit();
  }

  onSkip() {
    this.form.patchValue({ skip: true });
    this.submit.emit();
  }

  onResendEmail() {
    this.resendEmail.emit();
  }

  onEmailTechSupport() {
    this.emailTechSupport.emit();
  }

  onError(err: Error) {
    this.errorMessage = err.message;
    this.submitting = false;
  }
}
