import {
  MODULE_ACCOUNT_CREATION_PAGE,
  MODULE_BILLING,
  MODULE_EMAIL_VERIFICATION_PAGE,
  MODULE_LEGAL_DOCUMENT_PAGE,
  MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
} from '@app/core/mixpanel.constants';

export enum RegisterSteps {
  BasicInfo = 0,
  Location = 1,
  PersonalInfo = 2,
  TermsOfService = 3,
  Payment = 4,
  EmailVerification = 5,
  Confirmation = 6,
  Restart = 7,
}

export const STEPS_BACK_BUTTON_HIDDEN = [
  RegisterSteps.BasicInfo,
  RegisterSteps.Location,
  RegisterSteps.EmailVerification,
  RegisterSteps.Confirmation,
];

export const ACCOUNT_LOCKED_MODULE_VARIANT = 'Account Locked Error State Page';

export const REGISTER_MP_FLOW_VERSION = 'cons_reg_2020';

export const REGISTER_GA_PAGE_VIEW_EVENT = 'page view';

export const LD_PAYMENT_SUCCESS = 'Consumer Registration Successfully Paid';
export const LD_PAYMENT_SUCCESS_APPLE_PAY = 'Consumer Registration Successfully Paid with Apple Pay';
export const LD_PAYMENT_SUCCESS_AFTERPAY = 'Consumer Registration Successfully Paid with Afterpay';

export enum RegisterFlows {
  Paid = 'Paid',
  FreeTrial = 'FreeTrial',
  Gifting = 'Gifting',
  Referral = 'Referral',
  Family = 'Family',
}

export enum RegisterDiscountTypes {
  FreeTrial = 'FreeTrial',
  Regular = 'Regular',
  Referral = 'Referral',
  Enterprise = 'Enterprise',
  Gift = 'Gift',
}

export const RegisterAnalyticsConfig = {
  Paid: {
    BasicInfo_mp_module: MODULE_ACCOUNT_CREATION_PAGE,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: 'Address Page',
    PersonalInfo_mp_module: 'Phone DOB Sex Page',
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  FreeTrial: {
    BasicInfo_mp_module: 'Free Trial Account Creation Page',
    EmailVerification_mp_module: 'Free Trial Email Verification Page',
    Location_mp_module: 'Free Trial Address Page',
    PersonalInfo_mp_module: 'Free Trial Phone DOB Sex Page',
    TermsOfService_mp_module: 'Free Trial Legal Document Page',
    Payment_mp_module: 'Free Trial Billing Page',
    Confirmation_mp_module: 'Free Trial Registration Confirmation Page',
    ga_category: 'FreeTrialSignupForm',
    registration_success_mp_event: `Free Trial ${MP_EVENT_REG_SUCCESSFULLY_SUBMITTED}`,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  Gifting: {
    BasicInfo_mp_module: 'Gift Account Creation Page',
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: 'Address Page',
    PersonalInfo_mp_module: 'Phone DOB Sex Page',
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'GiftingSignupForm',
    registration_success_mp_event: MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Gift Registration',
    post_reg_mp_flow: 'Gift Registration',
  },
  Referral: {
    BasicInfo_mp_module: MODULE_ACCOUNT_CREATION_PAGE,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: 'Phone DOB Sex Page',
    PersonalInfo_mp_module: 'Personal Info Page',
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ReferralSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  Family: {
    BasicInfo_mp_module: MODULE_ACCOUNT_CREATION_PAGE,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: 'Phone DOB Sex Page',
    PersonalInfo_mp_module: 'Personal Info Page',
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Family Registration',
    post_reg_mp_flow: 'Post Registration',
  },
};

export const MEMBERSHIP_BENEFITS = [
  'Same-day appointments, in-person or remote, that start on time',
  'Easy access to COVID-19 testing and care',
  'On-demand 24/7 Video Chats with our virtual medical team at no extra cost',
  '90+ convenient locations with drop-in lab services',
  'Easy appointment booking and Rx renewals in the app',
  'Experts to help navigate the healthcare maze',
];
