import { Directive, ElementRef, HostListener, Input, OnInit, Optional } from '@angular/core';
import { Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, timeoutWith } from 'rxjs/operators';

import { WindowService } from '@app/core/window.service';

export type RouterLinkCommand = any[] | string;
@Directive({
  selector: '[omTrackLink]',
})
export class TrackLinkDirective implements OnInit {
  @Input() target: String;
  @Input() omTrackLink: () => Observable<unknown>;
  @Input() routerLink: RouterLinkCommand;
  @Input() state: Object;

  constructor(
    private router: Router,
    @Optional() private link: RouterLink,
    @Optional() private linkWithHref: RouterLinkWithHref,
    private windowService: WindowService,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.disableLink(this.link || this.linkWithHref);
  }

  private disableLink(link: RouterLink | RouterLinkWithHref) {
    if (link) {
      link.routerLink = [];
    }
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const newTab = event.metaKey || event.ctrlKey || this.target === '_blank';
    if (!this.routerLink) {
      event.preventDefault();
    }

    this.omTrackLink()
      .pipe(
        timeoutWith(300, observableOf({})),
        catchError(error => observableOf({})),
      )
      .subscribe(() => {
        if (this.routerLink) {
          if (newTab) {
            return;
          }
          if (Array.isArray(this.routerLink)) {
            this.router.navigate(this.routerLink, { state: this.state, queryParamsHandling: 'preserve' });
          } else {
            this.router.navigateByUrl(this.routerLink, { state: this.state });
          }
        } else {
          const href = this.el.nativeElement.getAttribute('href');
          this.windowService.redirect(href, { newTab });
        }
      });
  }
}
