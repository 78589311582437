import { Component, HostBinding, Input, OnInit } from '@angular/core';
import clamp from 'lodash-es/clamp';
import { BehaviorSubject } from 'rxjs';

import {
  AppointmentBookingStateService,
  AppointmentBookingState,
} from '@app/appointment/appointment-booking-state-service';
import { DayInventories, getFirstDateInventories } from '@app/appointment/provider-inventories';
import { ISSUE_REFINEMENT_PAGE, ISSUE_SELECTION_PAGE } from '@app/core/mixpanel.constants';
import { RegistrationStepDirective } from '@app/registration/enterprise/registration-step.directive';

import { RecommendedAppointmentInventory_patient_recommendedAppointmentInventory } from '../__generated__/RecommendedAppointmentInventory';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { tileOptions, TileSelectionOption, valuePropMapping } from './utils';

export const progressLevels = {
  INITIAL: 90,
  LOADING: 95,
  RESULTS: 100,
} as const;

@Component({
  selector: 'om-appointment-recommendation-step',
  templateUrl: './appointment-recommendation-step.component.html',
  styleUrls: ['./appointment-recommendation-step.component.scss'],
})
export class AppointmentRecommendationStepComponent extends RegistrationStepDirective implements OnInit {
  @HostBinding('class') class = 'flex-fill'; // Allows the component to take up the full space allowed by the flex container

  @Input() remoteRecommendation: RecommendedAppointmentInventory_patient_recommendedAppointmentInventory;
  @Input() previousStepSource: string;
  patientPreferredName: string;

  // TODO: placeholder to just get this component to render _something_
  selectedOption: TileSelectionOption;
  showResults = false;
  showLoading = false;
  tileOptions = tileOptions;
  initialSelections: string[] = [];
  valuePropMapping = valuePropMapping;
  progress$ = new BehaviorSubject<number>(progressLevels.INITIAL);
  bookingState: AppointmentBookingState;

  issuesRefined = false;

  loadingSpinnerDelay = 3000;

  constructor(
    private appointmentBookingStateService: AppointmentBookingStateService,
    public analyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  ngOnInit() {
    this.bookingState = this.appointmentBookingStateService.getAppointmentBookingState();
    this.bookingState.setPostRegistrationAppointment(true);
  }

  loadingEnd() {
    this.showResults = true;
    this.showLoading = false;
    this.progress$.next(progressLevels.RESULTS);
  }

  selectIssue(option: TileSelectionOption) {
    this.showResults = false;
    this.showLoading = true;
    this.selectedOption = option;

    this.bookingState.reason = this.getReason(option);
    this.progress$.next(progressLevels.LOADING);

    this.analyticsService.trackPostRegRecInterstitialPageViewed({
      source: this.issuesRefined ? ISSUE_REFINEMENT_PAGE : ISSUE_SELECTION_PAGE,
      issueSelected: this.selectedOption.title,
    });

    // Wait for whichever takes longest, remoteRecommendation fetch or the loading delay time
    setTimeout(() => {
      this.loadingEnd();

      // for lower level calls (trackScheduleVisitPageViewed, inventorySelected) that need this information this needs to be set here
      this.analyticsService.setIssueSelected(this.selectedOption.title);
    }, this.loadingSpinnerDelay);
  }

  onPreferenceIntakeLoaded() {
    this.analyticsService.trackIssueSelectionPageViewed({ source: this.previousStepSource });
  }

  onRecommendationResultsLoaded(inventories: DayInventories) {
    this.analyticsService.trackScheduleVisitPageViewed({
      source: this.issuesRefined ? ISSUE_REFINEMENT_PAGE : ISSUE_SELECTION_PAGE,
      // semi-roundabout way to get number of inventories shown in the next-remote-visit component
      // next-remote visit only shows <=5 of the inventories for a day, so we cap at 5
      inventoryCount: clamp(getFirstDateInventories(inventories).length, 5),
    });
  }

  multipleOptionsSelected($event: TileSelectionOption[]) {
    const issuesSelected = $event.map(option => option.title);
    this.analyticsService.trackIssueRefinementPagePageViewed({
      source: ISSUE_SELECTION_PAGE,
      issuesSelected: issuesSelected,
    });
    this.issuesRefined = true;
    this.initialSelections = issuesSelected;
  }

  private getReason(option: TileSelectionOption) {
    const reasonsAdditionalSelections = this.initialSelections.filter(selection => selection !== option.title);
    return reasonsAdditionalSelections.length > 0
      ? `Primary: ${option.title} | Secondary: ${reasonsAdditionalSelections.join(', ')}`
      : `${option.title}`;
  }
}
