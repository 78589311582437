export const FeatureFlags = {
  APPOINTMENT_BOOKING_ENHANCED_STEPS: 'appointment-booking-enhanced-steps',
  APPOINTMENT_BOOKING_STEP_BASED_NAVIGATION: 'appointment-booking-step-based-navigation',
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  CONSUMER_PEDIATRIC_REGISTRATION_FREE_TRIAL: 'consumer-pediatric-registration-free-trial',
  APPOINTMENT_RUNNING_LATE: 'appointment-running-late',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DPH_BANNER_MESSAGE: 'consumer-registration-dph-banner-message',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_UNIVERSAL_PROMOTIONAL_PRICING: 'consumer-registration-universal-promotional-pricing',
  SECURITY_MFA: 'security-mfa',
  NEW_PCP_SELECTION: 'new-pcp-selection',
  MEDICAL_RECORDS_DOWNLOAD: 'patient-medical-records-download-request',
  SECURITY_ANTIVIRUS: 'security-antivirus',
  LOCATION_ATTESTATION: 'location-attestation',
};
