import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-back-button',
  styleUrls: ['./back-button.component.scss'],
  templateUrl: './back-button.component.html',
})
export class BackButtonComponent {
  @Input() label = 'Go Back';
  @Output() back: EventEmitter<any> = new EventEmitter<any>();

  onBack($event: Event | MouseEvent) {
    $event.preventDefault();
    this.back.emit($event);
  }
}
