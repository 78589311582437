<div class="d-flex flex-column h-100 justify-content-between">
  <div class="container mt-5">
    <div class="text-center" data-cy="greeting">
      <h1>What can we help you with next?</h1>
      <p>These are the most common actions our new members like to do.</p>
    </div>
    <om-routing-group
      className="list-group align-self-center col pr-0 mt-5 pt-3"
      [options]="options$ | async"
      [formControl]="optionsControl"
    ></om-routing-group>
  </div>
  <div class="text-center mt-2">
    <a class="btn btn-link" (click)="skip()">I’m all set for now</a>
  </div>
</div>
