import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-spinner',
  providers: [],
  template: `
    <span *ngIf="size == 'extra-small'">
      <svg class="spinner animation-rotate extra-small">
        <circle class="animation-processing-extra-small-dash" cx="21" cy="21" r="19"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'small'">
      <svg class="spinner animation-rotate small">
        <circle class="animation-processing-small-dash" cx="26" cy="26" r="24"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'medium-small'">
      <svg class="spinner animation-rotate medium-small">
        <circle class="animation-processing-medium-small-dash" cx="31" cy="31" r="29"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'medium'">
      <svg class="spinner animation-rotate medium">
        <circle class="animation-processing-medium-dash" cx="51" cy="51" r="49"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'large'">
      <svg class="spinner animation-rotate large">
        <circle class="animation-processing-large-dash" cx="68.5" cy="68.5" r="66.5"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'extra-large'">
      <svg class="spinner animation-rotate extra-large">
        <circle class="animation-processing-extra-large-dash" cx="93.5" cy="93.5" r="91.5"></circle>
      </svg>
    </span>
  `,
  styleUrls: ['./spinner.component.scss'],
})
export class Spinner {
  @Input() size = 'small';
}
