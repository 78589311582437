import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  EMAIL_NOT_FOUND_ERROR_MESSAGE,
  WorkEmailNotFoundError,
} from '@app/registration/enterprise/whitelisted-employee-errors';
import { EmailInputComponent } from '@app/shared/email-input/email-input.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-work-email-step',
  templateUrl: './work-email-step.component.html',
  styleUrls: ['./work-email-step.component.scss'],
})
export class WorkEmailStepComponent extends RegistrationStepDirective {
  @ViewChild('workEmail', { static: false }) workEmail: EmailInputComponent;
  @ViewChild('membershipNotFound', { static: true }) membershipNotFound;
  @Input() errorMessage = '';
  @Input() shownView = '';
  @Input() experimentLoading = true;
  @Input() numberOfOffices: number;
  @Output() emailErrorModalViewed = new EventEmitter<void>();
  @Output() modalClosed = new EventEmitter<void>();

  hasError = false;
  goToActivationCode = false;
  customModalErrorMessage = '';
  emailNotFoundModal: NgbModalRef;

  constructor(public modalService: NgbModal) {
    super();
  }

  onLogIn(e: Event) {
    e.preventDefault();
    this.login.emit();
  }

  onError(err: Error) {
    this.submitting = false;
    this.hasError = true;
    if ((err as WorkEmailNotFoundError).workEmailNotFound) {
      this.emailNotFoundModal = this.modalService.open(this.membershipNotFound);
      this.emailNotFoundModal.dismissed.subscribe(() => {
        if (!this.goToActivationCode) {
          this.modalClosed.emit();
        }
      });
      this.emailErrorModalViewed.emit();
    }
  }

  onSubmit() {
    this.workEmail.markAsTouchedAndDirty();

    // triggering the blur and focus forces the email input to update and validate.
    this.workEmail.blur();
    this.workEmail.focus();
    this.submitting = true;
    this.submit.emit(this.form.value);
  }

  proceedToActivationCode(e: Event) {
    e.preventDefault();
    this.goToActivationCode = true;
    this.submit.emit(this.form.value);
  }

  modalSubheader() {
    return `${EMAIL_NOT_FOUND_ERROR_MESSAGE}\n` + this.customModalErrorMessage;
  }

  closeModal() {
    this.emailNotFoundModal.dismiss();
  }
}
