import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  CanActivate,
  Router,
  UrlTree,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { Auth0AuthService } from '@app/core/auth0-auth.service';
import { WindowService } from '@app/core/window.service';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(
    private attemptedPathService: AttemptedPathService,
    private authService: AuthService,
    private windowService: WindowService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.initialized$.pipe(
      map(() => {
        const params = new URLSearchParams(this.windowService.getLocationSearch());
        this.setAttemptedPathFromParams(params);

        if (this.authService.isLoggedIn()) {
          this.attemptedPathService.navigateToAttemptedPath();
          return false;
        }

        return this.activateCorrectLoginImplementation();
      }),
    );
  }

  activateCorrectLoginImplementation() {
    // if auth0 do not continue
    if (this.authService.implementation instanceof Auth0AuthService) {
      this.authService.goLogin();
      return false;
    }
    // not auth0 so okay to continue
    return true;
  }

  setAttemptedPathFromParams(params: URLSearchParams) {
    const path = params.get('returnUrl') || params.get('myone_path');
    if (path && path.length > 1) {
      const isMyonePath = path === params.get('myone_path');
      this.attemptedPathService.setAttemptedPath(path, isMyonePath);
    }
  }
}
