import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { BookableAppointmentTypes } from './__generated__/BookableAppointmentTypes';

@Injectable({
  providedIn: 'root',
})
export class BookableAppointmentTypesGraphQL extends Query<BookableAppointmentTypes> {
  document = gql`
    query BookableAppointmentTypes(
      $appointmentCategory: String
      $providerId: ID
      $serviceAreaId: ID
      $topicName: String
    ) {
      patient {
        id
        bookableAppointmentTypes(
          appointmentCategory: $appointmentCategory
          providerId: $providerId
          serviceAreaId: $serviceAreaId
          topicName: $topicName
        ) {
          id
          description
          displayName
          displayedDuration
          firstVisitAppointmentType {
            id
          }
          isBrief
          isPrimary
          isSpecialty
          isUrgent
          name
          position
          remote
          requiresHirAuthorization
        }
      }
    }
  `;
}
