import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AppointmentBookingStateService } from './appointment-booking-state.service';
import { AppointmentBookingStep } from './appointment-booking-steps';

@Injectable()
export class BookingProviderGuard implements CanActivate {
  private step: AppointmentBookingStep;

  constructor(private bookingStateService: AppointmentBookingStateService) {
    this.step = this.bookingStateService.getAppointmentBookingSteps().SelectProvider;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.step === this.bookingStateService.loadStep(this.step, false);
  }
}
