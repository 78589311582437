import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
} from '@angular/router';

import { ConfigService } from '@app/core/config.service';

import { WindowService } from './window.service';

@Injectable()
export class MyoneRedirectGuardService implements CanActivate {
  constructor(private config: ConfigService, private router: Router, private windowService: WindowService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const pathParam = route.queryParamMap.get('path') || '';
    // remove any initial `/` for consistency
    const path = pathParam[0] === '/' ? pathParam.substr(1) : pathParam;
    if (path) {
      this.windowService.redirect(`${this.config.json.myoneServer}/${path}`);
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }
}
