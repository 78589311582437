import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import get from 'lodash-es/get';
import { Observable, of as observableOf } from 'rxjs';

import { LinksService } from '@app/core/links.service';
import { MODULE_EMAIL_VERIFICATION_PAGE, MODULE_DIRECT_SIGN_UP_SELECTION_PAGE } from '@app/core/mixpanel.constants';
import {
  AppointmentRecommendationStepComponent,
  progressLevels,
} from '@app/registration/enterprise/appointment-recommendation/appointment-recommendation-step.component';
import { EnterpriseRegistration } from '@app/registration/enterprise/enterprise-registration';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';

import { RecommendedAppointmentInventory } from '../__generated__/RecommendedAppointmentInventory';
import { RecommendedAppointmentInventoryGraphQL } from '../recommended-appointment-inventory-graphql.service';
import { StepName } from '../registration-step-name';

@Injectable({
  providedIn: 'root',
})
export class AppointmentRecommendationConfig extends RegistrationStep {
  progress: number = progressLevels.INITIAL; // Starts at the previous step's progress (success-step)
  readonly component = AppointmentRecommendationStepComponent;
  readonly form: FormGroup;

  constructor(
    private recommendedInventoryGraphQL: RecommendedAppointmentInventoryGraphQL,
    private router: Router,
    private links: LinksService,
  ) {
    super();
  }

  initComponent(component: AppointmentRecommendationStepComponent, state: EnterpriseRegistration): void {
    // Including `EnterpriseRegistration` causes a circular dependency issue in the Step component
    component.previousStepSource = this.getPreviousStepSource(state);
    // Allows for updating progress bar within the step
    component.progress$.subscribe(progress => {
      this.progress = progress;
    });

    this.recommendedInventoryGraphQL
      .watch({
        recommendRemoteResults: true,
        serviceAreaId: `${state.patient.serviceArea.id}`,
      })
      .valueChanges.subscribe((result: ApolloQueryResult<RecommendedAppointmentInventory>) => {
        if (get(result, 'data.patient.recommendedAppointmentInventory')) {
          component.remoteRecommendation = result.data.patient.recommendedAppointmentInventory;
        } else {
          this.router.navigateByUrl(this.links.home);
        }
      });
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    state.setCurrentStep(StepName.concierge);
    return observableOf(true);
  }

  canGoBack() {
    return false;
  }

  private getPreviousStepSource(state: EnterpriseRegistration): string {
    const previousStepName = state.getPreviousStepName();
    if (previousStepName === StepName.success) {
      return MODULE_EMAIL_VERIFICATION_PAGE;
    } else if (previousStepName === StepName.directSignup) {
      return MODULE_DIRECT_SIGN_UP_SELECTION_PAGE;
    }
  }
}
