import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlToLink',
})
export class UrlToLinkPipe implements PipeTransform {
  transform(text: string, isNewTab = false) {
    return text.replace(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,256}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
      match => `<a href="${match}"${isNewTab ? ' target="_blank" rel="nofollow noopener"' : ''}>${match}</a>`,
    );
  }
}
