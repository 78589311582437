import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  RecommendedAppointmentInventory,
  RecommendedAppointmentInventoryVariables,
} from './__generated__/RecommendedAppointmentInventory';

@Injectable({
  providedIn: 'root',
})
export class RecommendedAppointmentInventoryGraphQL extends Query<
  RecommendedAppointmentInventory,
  RecommendedAppointmentInventoryVariables
> {
  document = gql`
    query RecommendedAppointmentInventory($recommendRemoteResults: Boolean!, $serviceAreaId: ID!, $providerId: ID) {
      patient {
        recommendedAppointmentInventory(
          recommendRemoteResults: $recommendRemoteResults
          serviceAreaId: $serviceAreaId
          providerId: $providerId
        ) {
          office {
            id
            title
            name
            serviceArea {
              id
              name
            }
          }
          appointmentType {
            id
            description
            displayedDuration
            name
            displayName
            isSpecialty
            position
            remote
          }
          provider {
            id
            firstName
            lastName
            bio
            displayName
            profileImageUrl
          }
          appointmentInventories {
            id
            startTime
            date
            duration
            utcStartDatetime
            timezoneName
            timezoneTzinfo
          }
        }
      }
    }
  `;
}
