import get from 'lodash-es/get';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppointmentCancellationReason } from '@app/appointment/appointment-cancellation-reason';
import { UserService } from '@app/core/user.service';

import { Provider } from '../../shared/provider';
import { ServiceArea } from '../../shared/service-area';
import { Task } from '../../shared/task';
import { VisitFollowUpOrderContent } from '../../shared/task-content.types';
import { SurveyData } from '../../survey/survey-data';
import { AppointmentBookingSession } from '../appointment-booking-session';
import { AppointmentType } from '../appointment-type';
import { FromAppointment } from '../from-appointment';
import { ProviderType, ProviderTypeUtil } from '../provider-type';

interface TrackableBookingState {
  appointment_type?: string;
  is_routed?: boolean;
  provider_category?: string;
  provider_id?: number;
  reason?: string;
  reason_for_cancel?: string;
  send_sms?: boolean;
  service_area?: string;
  routed_topic?: string;
  recommended_channel?: string;
  source?: string;
}

// Map appointment types to surveys
export const enum HardcodedAppointmentTypeId {
  IUDInsertion = 176,
  IUDRemoval = 177,
  IUDReplacement = 178,
}
export const enum HardcodedSurveyId {
  IUDInsertion = 17, // insertion survey is for replacement too
  IUDRemoval = 18,
}

export const PROVIDER_TYPE_DEFAULT = ProviderType.anyAvailableProvider;

function appointmentTypeIdToSurveyId(appointmentTypeId: number): HardcodedSurveyId | undefined {
  const appointmentSurveyMap = new Map([
    [HardcodedAppointmentTypeId.IUDInsertion, HardcodedSurveyId.IUDInsertion],
    [HardcodedAppointmentTypeId.IUDRemoval, HardcodedSurveyId.IUDRemoval],
    [HardcodedAppointmentTypeId.IUDReplacement, HardcodedSurveyId.IUDInsertion],
  ]);
  return appointmentSurveyMap.get(appointmentTypeId);
}

export class AppointmentBookingState {
  private _associatedTask: Task;
  private _isAppointmentRecommended = new BehaviorSubject<boolean>(false);
  private _selectedServiceArea$ = new BehaviorSubject<ServiceArea>(null);

  // This is a kind of hacky way to track if user is on appointment booking through a post-reg booking flow
  // TODO: figure out a better way to track appointment booking properties cross-flows
  private postRegistrationAppointment = false;

  appointmentType: AppointmentType;
  cancellationReason: AppointmentCancellationReason | undefined;
  createdPatientSurveyId: number;
  enhancedBookingEnabled: boolean;
  freeTextReason = '';
  fromAppointment: FromAppointment | undefined;
  isRecommendedRemoteAppointment = false;
  phoneNumber: string;
  recommendedChannel: string;
  requiredSurveyId: number;
  routedTopic: string;
  routingMetadataUuid: string;
  selectedProvider: Provider;
  selectedProviderType: ProviderType = ProviderType.anyAvailableProvider;
  sendSms: boolean;
  surveyData: SurveyData | undefined;

  isAppointmentRecommended$ = this._isAppointmentRecommended.asObservable();
  selectedServiceArea$: Observable<ServiceArea> = this._selectedServiceArea$
    .asObservable()
    .pipe(filter(sa => sa != null));

  get reason() {
    if (this.freeTextReason.length > 128) {
      return this.freeTextReason.trim().slice(0, 127) + '\u2026'; // ellipsis
    } else {
      return this.freeTextReason;
    }
  }

  set reason(reason) {
    this.freeTextReason = reason;
  }

  get associatedTask() {
    return this._associatedTask;
  }

  set associatedTask(task) {
    this._associatedTask = task;
    if (task.content instanceof VisitFollowUpOrderContent) {
      if (task.content.visitReason) {
        this.freeTextReason = task.content.visitReason;
      }
    }
  }

  get bookingParams() {
    const params = {
      appointment: {
        appointment_cancellation_reason_id: this.getCancellationReasonId(),
        appointment_type_id: this.getAppointmentTypeId(),
        from_appointment_id: this.getFromAppointmentId(),
        patient_survey_id: this.createdPatientSurveyId,
        reason: this.reason,
        routing_metadata_uuid: this.routingMetadataUuid,
        send_sms: this.sendSms,
        sms_number: this.sendSms ? this.phoneNumber : null,
        visit_reason_categories: null,
      },
    };

    if (this.associatedTask) {
      params['appointment']['booked_from'] = {
        resource: this.associatedTask.type,
        id: this.associatedTask.id,
      };
    }

    return params;
  }

  get trackableProperties(): TrackableBookingState {
    const properties: TrackableBookingState = {};
    if (this.freeTextReason) {
      properties.reason = this.reason;
      properties.is_routed = this.getIsAppointmentRecommended();
    }

    if (!!this.appointmentType) {
      properties.appointment_type = this.appointmentType.displayName;
    }

    const selectedServiceArea = this.getSelectedServiceArea();
    if (!!selectedServiceArea) {
      properties.service_area = selectedServiceArea.name;
    }

    if (this.selectedProviderType != null) {
      properties.provider_category = ProviderTypeUtil.getTypeDescription(this.selectedProviderType);
    }

    const provider = this.selectedProvider;
    if (!!provider) {
      properties.provider_id = provider.id;
      properties.provider_category = properties.provider_category || 'Specific Provider: ' + provider.preparedName;
    }

    if (this.sendSms) {
      properties.send_sms = true;
    }

    if (this.postRegistrationAppointment) {
      properties.source = 'Schedule Visit Page';
    } else if (this.isRecommendedRemoteAppointment) {
      properties.source = 'Next Available Remote Visit Widget';
    }

    if (this.getCancellationReasonId()) {
      properties.reason_for_cancel = this.cancellationReason.displayReason;
    }

    properties.routed_topic = this.routedTopic;
    properties.recommended_channel = this.recommendedChannel;

    return properties;
  }

  static fromAppointmentBookingSession(
    response: AppointmentBookingSession,
    userService: UserService,
  ): Observable<AppointmentBookingState> {
    const bookingState = new AppointmentBookingState();
    const { appointmentBookingStateCache } = response;
    const { appointmentBookingState } = appointmentBookingStateCache;

    // return an empty booking state if the graphql response is empty
    if (!appointmentBookingState) {
      return observableOf(bookingState);
    }

    const appointmentReason = get(response, 'appointmentBookingStateCache.appointmentReason');
    if (!!appointmentReason) {
      bookingState.freeTextReason = appointmentReason;
    }

    // Use service area object to set selected service area;
    const serviceAreaSet$ = bookingState.getServiceAreaSet(appointmentBookingState, userService);

    return serviceAreaSet$.pipe(
      map((copyOfBookingState: AppointmentBookingState) =>
        copyOfBookingState.setAttributesFromAppointmentBookingSession(appointmentBookingState),
      ),
    );
  }

  setAttributesFromAppointmentBookingSession(appointmentBookingSession: any) {
    if (appointmentBookingSession.appointmentType) {
      this.setSelectedAppointmentType(AppointmentType.fromGraphQL(appointmentBookingSession.appointmentType));
    }

    if (appointmentBookingSession.isAppointmentRecommended) {
      this.setIsAppointmentRecommended(appointmentBookingSession.isAppointmentRecommended);
    }

    if (appointmentBookingSession.fromAppointment) {
      this.setFromAppointment(FromAppointment.fromApiV2(appointmentBookingSession.fromAppointment));
    }

    if (appointmentBookingSession.appointmentCancellationReason) {
      this.setCancellationReason(
        AppointmentCancellationReason.fromApiV2(appointmentBookingSession.appointmentCancellationReason),
      );
    }

    // unset selected provider and set to anyAvailableProvider
    this.setSelectedProviderType(ProviderType.anyAvailableProvider);
    this.setSelectedProvider(null);

    return this;
  }

  getServiceAreaSet(appointmentBookingState: any, userService: UserService) {
    if (appointmentBookingState.serviceArea) {
      this.setSelectedServiceArea(ServiceArea.fromAppointmentBookingSession(appointmentBookingState.serviceArea));
      return observableOf(this);
    } else {
      return userService.user$.pipe(
        map(user => {
          this.setSelectedServiceArea(new ServiceArea(user.serviceArea));
          return this;
        }),
      );
    }
  }

  setIsRecommendedRemoteAppointment(isRecommendedRemoteAppointment: boolean) {
    this.isRecommendedRemoteAppointment = isRecommendedRemoteAppointment;
  }

  getIsRecommendedRemoteAppointment() {
    return this.isRecommendedRemoteAppointment;
  }

  setIsAppointmentRecommended(isAppointmentRecommended: boolean) {
    this._isAppointmentRecommended.next(isAppointmentRecommended);
  }

  getIsAppointmentRecommended() {
    return this._isAppointmentRecommended.value;
  }

  setPostRegistrationAppointment(isFromPostRegistration: boolean) {
    this.postRegistrationAppointment = isFromPostRegistration;
  }

  setSelectedServiceArea(serviceArea: ServiceArea) {
    this._selectedServiceArea$.next(serviceArea);
  }

  getSelectedServiceArea(): ServiceArea {
    return this._selectedServiceArea$.value;
  }

  setSelectedProviderType(providerType: ProviderType) {
    const ensureProviderType = Object.values(ProviderType).includes(providerType)
      ? providerType
      : PROVIDER_TYPE_DEFAULT;
    this.selectedProviderType = ensureProviderType;
  }

  setSelectedProvider(provider: Provider | null) {
    this.selectedProvider = provider;
  }

  resetProviderSelection() {
    this.selectedProviderType = PROVIDER_TYPE_DEFAULT;
    this.selectedProvider = null;
  }

  getSelectedProviderId(): number {
    if (this.selectedProvider) {
      return this.selectedProvider.id;
    }
  }

  setSelectedAppointmentType(appointmentType: AppointmentType, resetSurvey = true) {
    if (appointmentType) {
      this.appointmentType = appointmentType;
    }
    if (resetSurvey) {
      this.resetSurvey();
    }
  }

  setCancellationReason(cancellationReason: AppointmentCancellationReason) {
    this.cancellationReason = cancellationReason;
  }

  getCancellationReasonId(): number {
    return this.cancellationReason ? this.cancellationReason.id : undefined;
  }

  setFromAppointment(fromAppointment: FromAppointment) {
    this.fromAppointment = fromAppointment;
  }

  getFromAppointmentId(): number {
    return this.fromAppointment ? this.fromAppointment.id : undefined;
  }

  getEnhancedBookingEnabled() {
    return this.enhancedBookingEnabled;
  }

  setEnhancedBookingEnabled(enabled: boolean) {
    this.enhancedBookingEnabled = enabled;
  }

  resetSurvey() {
    this.requiredSurveyId = appointmentTypeIdToSurveyId(this.getAppointmentTypeId());
    this.createdPatientSurveyId = undefined;
    this.surveyData = undefined;
  }

  serviceAreaSet(): boolean {
    return this.getSelectedServiceArea() != null;
  }

  providerTypeSelected(): boolean {
    return this.selectedProviderType != null;
  }

  appointmentTypeSelected(): boolean {
    return this.appointmentType != null;
  }

  bookingStateComplete(): boolean {
    return (
      (this.freeTextReason &&
        this.providerTypeSelected() &&
        this.serviceAreaSet() &&
        this.appointmentTypeSelected() &&
        (!this.requiredSurveyId || (this.requiredSurveyId && !!this.surveyData))) ||
      false
    );
  }

  toApiV2() {
    const api_v2_data = {
      appointment_booking_state: {
        appointment_cancellation_reason: this.getCancellationReasonId() ? this.cancellationReason.toApiV2() : null,
        appointment_type: this.appointmentType ? this.appointmentType.toApiV2() : null,
        is_appointment_recommended: this.getIsAppointmentRecommended(),
        provider: this.selectedProvider != null ? this.selectedProvider.toApiV2() : null,
        provider_type: this.selectedProviderType,
        selected_reasons: [{ text: this.freeTextReason }], // backward compat from old problem typeahead which used selected_reasons
        service_area: this.getSelectedServiceArea().toApiV2(),
      },
      appointment_category: ProviderTypeUtil.getTypeCategory(this.selectedProviderType),
      appointment_reason: this.reason,
      appointment_type_id: this.getAppointmentTypeId(),
      provider_id: this.getSelectedProviderId(),
      service_area_id: this.getSelectedServiceArea().id,
      task: this.associatedTask != null ? this.associatedTask.id : null,
      visit_reason_categories: null,
    };

    if (this.fromAppointment) {
      api_v2_data['appointment_booking_state']['from_appointment'] = this.fromAppointment.toApiV2();
    }
    return api_v2_data;
  }

  private getAppointmentTypeId() {
    return this.appointmentType ? this.appointmentType.id : undefined;
  }

  updateRecommendedAppointmentType(recommendedAppointmentType?: AppointmentType, channel?: string, topic?: string) {
    if (!recommendedAppointmentType) {
      this.setSelectedAppointmentType(null);
      this.routedTopic = undefined;
      this.recommendedChannel = undefined;
      this.setIsAppointmentRecommended(false);
    } else {
      this.routedTopic = topic;
      this.recommendedChannel = channel;
      this.setSelectedAppointmentType(recommendedAppointmentType);
      this.setIsAppointmentRecommended(true);
    }
  }
}
