import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { User } from '@app/shared/user';

import { AuthService } from './auth.service';
import { LinksService } from './links.service';
import { UserService } from './user.service';
import { WindowService } from './window.service';

@Injectable()
export class RegistrationCompleteGuardService implements CanActivate {
  private desiredRoute: string;

  constructor(
    private authService: AuthService,
    private links: LinksService,
    private userService: UserService,
    private windowService: WindowService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.desiredRoute = state.url.split('?')[0];

    // TODO: investigate valid use cases for this guard & refactor.

    // registration check business logic
    const registrationCheck = () =>
      this.userService.getUser(true).pipe(
        map((patient: User) => {
          if (this.isEnterpriseReg(this.desiredRoute)) {
            return this.canActivateEnterpriseRegistration();
          } else if (this.isLegacyRegistration(this.desiredRoute)) {
            return this.canActivateRegistration(patient);
          } else {
            return this.canActivateNonRegistration(patient);
          }
        }),
        catchError(error => {
          this.authService.goLogin();
          return observableOf(false);
        }),
      );

    // authCheck business logic
    const authCheck = () =>
      this.authService.initialized$.pipe(
        map(() => {
          if (this.authService.isTokenBlank() && this.isRegistration(this.desiredRoute)) {
            return true;
          }
          if (this.authService.isTokenBlank()) {
            return false;
          }
          return null;
        }),
      );

    // control flow return
    return authCheck().pipe(
      concatMap(val => {
        if (val !== null) {
          return observableOf(val);
        }
        return registrationCheck();
      }),
    );
  }

  private canActivateEnterpriseRegistration(): boolean {
    return true;
  }

  private canActivateRegistration(patient: User): boolean {
    if (patient.isRegComplete) {
      this.router.navigateByUrl('/');
      return false;
    }

    return true;
  }

  private canActivateNonRegistration(patient: User): boolean {
    if (patient.isRegComplete) {
      return true;
    } else {
      this.router.navigate(['/register']);
    }
    return false;
  }

  private isRegistration(url: string): boolean {
    return url === '/registration' || this.isEnterpriseReg(url);
  }

  private isLegacyRegistration(url: string): boolean {
    return url === '/registration';
  }

  private isEnterpriseReg(url: string): boolean {
    return url.startsWith('/registration/enterprise');
  }
}
