<div *ngIf="style === addressDisplayStyle.INPUT; else default">
  <div class="form-group">
    <om-label-wrapper label="Address" class="">
      <input type="text" class="form-control text-field" readonly [value]="fullAddress" />
    </om-label-wrapper>
  </div>
  <div class="form-group">
    <om-label-wrapper label="Address 2" *ngIf="address.address2" class="">
      <input type="text" class="form-control text-field" readonly [value]="address.address2" />
    </om-label-wrapper>
  </div>
</div>
<ng-template #default>
  <address>
    {{ address.address1 }} <br />
    <ng-container *ngIf="address.address2?.length > 0"> {{ address.address2 }} <br /> </ng-container>
    {{ address.city }}, {{ address.state }} {{ address.zip }}
  </address>
</ng-template>
