import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberToLink',
})
export class PhoneNumberToLinkPipe implements PipeTransform {
  transform(text: string) {
    return text.replace(
      /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g,
      match => `<a href="tel:${match}">${match}</a>`,
    );
  }
}
