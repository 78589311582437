import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { MODULE_LEGAL_DOCUMENT_PAGE } from '@app/core/mixpanel.constants';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { RegistrationService } from '../../registration.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { TermsOfServiceStepComponent } from './terms-of-service-step.component';

@Injectable()
export class TermsOfServiceConfig extends RegistrationStep {
  GA_LABEL = 'ToS_Step';
  MODULE = MODULE_LEGAL_DOCUMENT_PAGE;

  component = TermsOfServiceStepComponent;
  componentInstance: TermsOfServiceStepComponent;
  progress = 70;
  form = null;

  constructor(
    private registrationService: RegistrationService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  initComponent(component: TermsOfServiceStepComponent, state: EnterpriseRegistration) {
    component.user = state.patient;
    component.signer = state.patient;
    this.componentInstance = component;
    this.trackPageView();
  }

  submit(state: EnterpriseRegistration): Observable<any> {
    return this.registrationService.markRegComplete().pipe(
      map(() => {
        this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
          module: this.MODULE,
          isWhitelist: state.isWhitelisted,
        });
        state.setCurrentStep(StepName.emailVerification);
        return observableOf(true);
      }),
    );
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }
}
