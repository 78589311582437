import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  HEALTH_HISTORY_ADULT_SURVEY_ID,
  HEALTH_HISTORY_PEDIATRIC_SURVEY_ID,
} from '@app/home/home-page/home-page.constants';

import { UserAlert } from '../shared/user-alert';
import { ApiService } from './api.service';

@Injectable()
export class UserAlertService {
  static DISMISSABLE_ACTIONS = {
    survey: {
      [HEALTH_HISTORY_ADULT_SURVEY_ID]: 'health_history_survey',
      [HEALTH_HISTORY_PEDIATRIC_SURVEY_ID]: 'health_history_survey',
    },
  };

  constructor(private apiService: ApiService) {}

  getAlert(key: string): Observable<UserAlert> {
    return this.apiService.get(`/api/v2/user_alerts/for_key?alert_key=${key}`).pipe(
      map((response: any) => {
        if (response.user_alert && response.user_alert.length) {
          return UserAlert.fromApiV2(response.user_alert[0]);
        } else {
          return null;
        }
      }),
    );
  }

  dismissAlert(key: string) {
    const params = { alert_key: key };
    const request = this.apiService.post('/api/v2/user_alerts/dismiss_alert', params);
    request.subscribe();
    return request;
  }

  dismissIfDismissable(object: string, id: string) {
    const alertKey = this.getDismissableActionKey(object, id);
    if (alertKey) {
      this.dismissAlert(alertKey);
    }
  }

  private getDismissableActionKey(object: string, id: string): string {
    if (object in UserAlertService.DISMISSABLE_ACTIONS) {
      return UserAlertService.DISMISSABLE_ACTIONS[object][id];
    }
  }
}
