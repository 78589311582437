import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { StepName } from '@app/registration/enterprise/registration-step-name';
import { OmRecaptchaComponent } from '@app/shared';

import { EnterpriseRegistration } from './enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from './enterprise-registration-analytics.service';
import { RegistrationStepDirective } from './registration-step.directive';

export interface IRegistrationStep {
  GA_ACTION: string;
  GA_LABEL: string;
  MODULE: string;
  canGoBack: (state: EnterpriseRegistration) => boolean;
  trackPageVisit: (
    state: EnterpriseRegistration,
    enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) => void;
  captchaNeeded: boolean;
  component: new (...params: any[]) => RegistrationStepDirective;
  componentInstance?: RegistrationStepDirective;
  form: FormGroup;
  initComponent: (component: any, state: EnterpriseRegistration) => void;
  intendedNextStep: StepName;
  onDestroy: () => void;
  patchParams: (params: { [k: string]: any }) => void;
  progress: number;
  shouldFastForward: boolean;
  submit: (state: EnterpriseRegistration, captcha: OmRecaptchaComponent) => Observable<any>;
  showLoginLink: boolean;
}

export abstract class RegistrationStep implements IRegistrationStep {
  GA_ACTION = 'page view';
  GA_LABEL: string;
  MODULE: string;
  captchaNeeded = false;
  intendedNextStep: StepName = null;
  shouldFastForward = false;
  showLoginLink = false;

  abstract component: new (...params: any[]) => RegistrationStepDirective;
  abstract form: FormGroup;
  abstract progress: number;

  trackPageVisit(state: EnterpriseRegistration, analytics: EnterpriseRegistrationAnalyticsService) {
    analytics.pageViewed({
      module: this.MODULE,
      isWhitelist: state.isWhitelisted,
    });
  }

  canGoBack(): boolean {
    return true;
  }

  onDestroy() {}

  patchParams(params: any) {}

  abstract initComponent(component: any, state: EnterpriseRegistration): void;
  abstract submit(state: EnterpriseRegistration, captcha: OmRecaptchaComponent): Observable<any>;
}
