import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgrxFormsModule } from 'ngrx-forms';

import { AppointmentAnalyticsService } from '@app/appointment/appointment-analytics.service';
import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { AppointmentSearchStateService } from '@app/appointment/appointment-search-service/appointment-search-state.service';
import { AppointmentSearchService } from '@app/appointment/appointment-search.service';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { AppointmentConfirmationModalComponent } from '@app/shared/appointment-confirmation-modal/appointment-confirmation-modal.component';
import { CouponService } from '@app/shared/coupon.service';
import { DropdownFormComponent } from '@app/shared/dropdown-form/dropdown-form.component';
import { EmailVerificationComponent } from '@app/shared/email-verification/email-verification.component';
import { ExcerptPipe } from '@app/shared/excerpt.pipe';
import { FeatureFlagByB2bIDGraphQL, FeatureFlagGraphQL } from '@app/shared/feature-flag-graphql.service';
import { NextRemoteVisitComponent } from '@app/shared/next-remote-visit/next-remote-visit.component';
import { OfficeService } from '@app/shared/office.service';
import { PatientExperimentService } from '@app/shared/patient-experiment.service';
import { StripePaymentRequestButtonComponent } from '@app/shared/stripe-credit-card/stripe-payment-request-button.component';
import { SvgRequestRecordsComponent } from '@app/shared/svg/svg-request-records/svg-request-records.component';

import {
  AddressAutocompleteComponent,
  AddressBasicInputComponent,
  AddressDisplayComponent,
  AddressOptionInputComponent,
  AddToCalendarComponent,
  AdultDirectSignupFormComponent,
  AmfValuePropsComponent,
  AnonymousFlagDataGraphQL,
  AnonymousFlagDataService,
  AppStoreRedirectService,
  BackButtonComponent,
  CardComponent,
  CardDirective,
  CardListComponent,
  CardListEmptyStateBodyDirective,
  CardListEmptyStateDirective,
  CardListHeaderDirective,
  CardListHeaderLinkDirective,
  CardListRowDirective,
  CardListSectionDirective,
  CardSliderComponent,
  CardTableColDirective,
  CardTableComponent,
  CardTableEmptyStateDirective,
  CardTableHeaderDirective,
  CardTableRowDirective,
  CardTableRowFooterDirective,
  CarouselModalComponent,
  CheckboxComponent,
  CheckboxInputComponent,
  CheckmarkListComponent,
  ConfirmationModalComponent,
  ContentBlockService,
  CovidVaccinationNextSteps,
  CreateHealthInformationReleaseAuthorizationGraphqlService,
  CreditCardComponent,
  DateInputComponent,
  DeleteConfirmationInlineFormComponent,
  DescriptionListCardComponent,
  DescriptionListCardHeaderDirective,
  DescriptionListCardKeyDirective,
  DescriptionListCardRowDirective,
  DescriptionListCardValueDirective,
  DirectSignupBannerComponent,
  DirectSignupComponent,
  DownloadAppCardComponent,
  DownloadAppLinkInputComponent,
  EmailInputComponent,
  EmailVerificationRegistrationComponent,
  ExpandingTextBox,
  ExpiredMembershipBannerComponent,
  FileSizePipe,
  FlashComponent,
  FocusTargetDirective,
  FooterComponent,
  HealthInformationReleaseFormComponent,
  HealthInformationReleaseService,
  IconItemComponent,
  InfoCardBodyDirective,
  InfoCardComponent,
  InfoCardHeaderDirective,
  InlineFormComponent,
  InviteFriendsModalComponent,
  IsErrorStatePipe,
  IsLoadingStatePipe,
  IsSuccessStatePipe,
  LabelWrapper,
  LegalAgreementComponent,
  LegalDocComponent,
  LoggedInWrapperComponent,
  LogoSpinner,
  MapsApiConfig,
  ModalTemplateComponent,
  MomentPipe,
  NameInputComponent,
  NavbarAnalyticsService,
  NavbarComponent,
  OfficeDetailsComponent,
  OmguiActionComponent,
  OmguiCheckboxComponent,
  OmguiClearableTextInputComponent,
  OmguiSummaryRowComponent,
  OmguiSummaryRowExpandableContentDirective,
  OmguiSummaryRowIconDirective,
  OmguiSummaryRowPrimaryTextDirective,
  OmguiSummaryRowSecondaryTextDirective,
  OmguiSummaryRowTertiaryTextDirective,
  OmRecaptchaComponent,
  PaginationComponent,
  PasswordInputComponent,
  PhoneNumberInputComponent,
  PhoneNumberPipe,
  PhoneNumberToLinkPipe,
  ProfileBubble,
  ProfileBubbleWithDetailsComponent,
  ProgramPromotionCardComponent,
  PusherService,
  RadioGroupComponent,
  RadioInputNgrxComponent,
  RedirectPromptModalComponent,
  ReferralBannerComponent,
  RoutingGroupComponent,
  RoutingGroupOptionComponent,
  SeparatorComponent,
  ServiceAreaSelectComponent,
  SexGenderInputComponent,
  SidebarComponent,
  SmallCardComponent,
  SmallCardSvgDirective,
  Spinner,
  StartCasePipe,
  StateService,
  StripeCreditCardComponent,
  StripeService,
  StyledMapComponent,
  SubmitButton,
  TaskService,
  ToastComponent,
  ToggleComponent,
  TosComponent,
  TrackLinkDirective,
  TruncatedMarkdown,
  UrlToLinkPipe,
} from '.';
import { ConfigService } from '../core/config.service';
import { DetectResizeDirective } from './detect-resize/detect-resize.directive';
import { DetectResizeService } from './detect-resize/detect-resize.service';
import { FooterAnalyticsService } from './footer/footer-analytics.service';
import { LabelWithTooltipComponent } from './label-with-tooltip/label-with-tooltip.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NativeAppService } from './native-app.service';
import { OmguiBadgeComponent } from './omgui/omgui-badge/omgui-badge.component';
import { PaymentModalAnalyticsService } from './payment-modal/payment-modal-analytics.service';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { RecommendationPreferenceIntakeComponent } from './registration/recommendation-preference-intake/recommendation-preference-intake.component';
import { RecommendationResultsComponent } from './registration/recommendation-results/recommendation-results.component';
import { SelfPayConfirmationComponent } from './self-pay-confirmation/self-pay-confirmation.component';
import {
  ArrowBackComponent,
  ArrowLeftComponent,
  ArrowNextComponent,
  ArrowRightComponent,
  CalendarComponent,
  CircleWithCheckmarkComponent,
  CloseComponent,
  CloseXComponent,
  ExclamationInBubbleComponent,
  LocationOnMapComponent,
  LogoComponent,
  LogoSmComponent,
  PencilComponent,
  ProviderComponent,
  ShowPasswordComponent,
  SpecialtyComponent,
  Svg,
  SvgAfterpayLogoComponent,
  SvgAllergyComponent,
  SvgApplepayLogoComponent,
  SvgBikeComponent,
  SvgCaretDownComponent,
  SvgCaretRightComponent,
  SvgCaretUpComponent,
  SvgCheckmarkComponent,
  SvgCopyIcon,
  SvgDownloadAppComponent,
  SvgExclamationComponent,
  SvgEyeClosedComponent,
  SvgEyeOpenComponent,
  SvgFacebookLinkComponent,
  SvgFamilyComponent,
  SvgGermComponent,
  SvgGiftBoxIcon,
  SvgHamburgerComponent,
  SvgHealthInformationExchangeComponent,
  SvgInfoComponent,
  SvgInstagramLinkComponent,
  SvgLabResultComponent,
  SvgLinkedInLinkComponent,
  SvgMedicationComponent,
  SvgMembershipAndBillingComponent,
  SvgMessageComponent,
  SvgNotificationSettingsComponent,
  SvgPhoneInBlueCircle,
  SvgPhoneInGreenCircle,
  SvgProfileComponent,
  SvgProgressPieComponent,
  SvgStethoscopeComponent,
  SvgTwitterLinkComponent,
  SvgVaccineComponent,
  SvgVirtualVisitComponent,
  SvgVitalsComponent,
  SvgWatchComponent,
  SvgYoutubeLinkComponent,
  ToggleCaretComponent,
  UserOnCompComponent,
  ValidationXComponent,
  XWithCircleComponent,
} from './svg';
import { SvgActionItemComponent } from './svg/svg-action-item/svg-action-item.component';
import { SvgCameraComponent } from './svg/svg-camera/svg-camera.component';
import { SvgVaccinesComponent } from './svg/svg-vaccines/svg-vaccines.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ViewMoreComponent } from './view-more/view-more.component';

@NgModule({
  declarations: [
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BackButtonComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardListComponent,
    CardListEmptyStateDirective,
    CardListEmptyStateBodyDirective,
    CardListHeaderDirective,
    CardListHeaderLinkDirective,
    CardListRowDirective,
    CardListSectionDirective,
    CardSliderComponent,
    CardTableComponent,
    CardTableColDirective,
    CardTableEmptyStateDirective,
    CardTableHeaderDirective,
    CardTableRowDirective,
    CardTableRowFooterDirective,
    CarouselModalComponent,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    CloseComponent,
    CloseXComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateInputComponent,
    DeleteConfirmationInlineFormComponent,
    DirectSignupComponent,
    AdultDirectSignupFormComponent,
    IconItemComponent,
    DescriptionListCardComponent,
    DescriptionListCardHeaderDirective,
    DescriptionListCardRowDirective,
    DescriptionListCardKeyDirective,
    DescriptionListCardValueDirective,
    DirectSignupBannerComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    EmailInputComponent,
    EmailVerificationRegistrationComponent,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    FooterComponent,
    HealthInformationReleaseFormComponent,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InviteFriendsModalComponent,
    IsErrorStatePipe,
    IsLoadingStatePipe,
    IsSuccessStatePipe,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    SidebarComponent,
    MomentPipe,
    NameInputComponent,
    NavbarComponent,
    NextRemoteVisitComponent,
    OfficeDetailsComponent,
    OmRecaptchaComponent,
    OmguiActionComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    OmguiSummaryRowComponent,
    OmguiSummaryRowExpandableContentDirective,
    OmguiSummaryRowIconDirective,
    OmguiSummaryRowPrimaryTextDirective,
    OmguiSummaryRowSecondaryTextDirective,
    OmguiSummaryRowTertiaryTextDirective,
    PaginationComponent,
    PasswordInputComponent,
    PaymentModalComponent,
    RedirectPromptModalComponent,
    PencilComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubble,
    ProfileBubbleWithDetailsComponent,
    ProgramPromotionCardComponent,
    ProviderComponent,
    RadioInputNgrxComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    ShowPasswordComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    Spinner,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgActionItemComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgVitalsComponent,
    SvgCameraComponent,
    SvgCopyIcon,
    SvgCheckmarkComponent,
    SvgGermComponent,
    SvgDownloadAppComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgExclamationComponent,
    SvgFacebookLinkComponent,
    SvgFamilyComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInfoComponent,
    SvgInstagramLinkComponent,
    SvgLabResultComponent,
    SvgLinkedInLinkComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgRequestRecordsComponent,
    SvgStethoscopeComponent,
    SvgTwitterLinkComponent,
    SvgVaccineComponent,
    SvgVirtualVisitComponent,
    SvgYoutubeLinkComponent,
    SvgWatchComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TrackLinkDirective,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    XWithCircleComponent,
    DirectSignupBannerComponent,
    PasswordInputComponent,
    ShowPasswordComponent,
    ModalTemplateComponent,
    SvgCaretUpComponent,
    SvgCaretDownComponent,
    SvgCaretRightComponent,
    DescriptionListCardComponent,
    AddressDisplayComponent,
    DetectResizeDirective,
    ViewMoreComponent,
    DropdownFormComponent,
    OmguiBadgeComponent,
    SvgVaccinesComponent,
    RecommendationPreferenceIntakeComponent,
    RecommendationResultsComponent,
    LabelWithTooltipComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    EmailVerificationComponent,
  ],
  imports: [
    AgmCoreModule.forRoot(),
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgrxFormsModule,
    RouterModule,
    RecaptchaModule,
  ],
  providers: [
    AnonymousFlagDataGraphQL,
    AnonymousFlagDataService,
    AppStoreRedirectService,
    AppointmentAnalyticsService,
    AppointmentBookingStateService,
    AppointmentSearchService,
    AppointmentSearchStateService,
    CreateHealthInformationReleaseAuthorizationGraphqlService,
    ContentBlockService,
    CouponService,
    DetectResizeService,
    FeatureFlagGraphQL,
    FeatureFlagByB2bIDGraphQL,
    FooterAnalyticsService,
    HealthInformationReleaseService,
    NativeAppService,
    MembershipGraphQL,
    NavbarAnalyticsService,
    OfficeService,
    PatientExperimentService,
    PaymentModalAnalyticsService,
    PusherService,
    StateService,
    StripeService,
    TaskService,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: MapsApiConfig,
      deps: [ConfigService],
    },
  ],
  exports: [
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BackButtonComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardListComponent,
    CardListEmptyStateDirective,
    CardListEmptyStateBodyDirective,
    CardListHeaderDirective,
    CardListHeaderLinkDirective,
    CardListRowDirective,
    CardListSectionDirective,
    CardSliderComponent,
    CardTableComponent,
    CardTableColDirective,
    CardTableEmptyStateDirective,
    CardTableHeaderDirective,
    CardTableRowDirective,
    CarouselModalComponent,
    CardTableRowFooterDirective,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    CloseComponent,
    CloseXComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateInputComponent,
    DeleteConfirmationInlineFormComponent,
    DirectSignupComponent,
    AdultDirectSignupFormComponent,
    IconItemComponent,
    DescriptionListCardComponent,
    DescriptionListCardHeaderDirective,
    DescriptionListCardRowDirective,
    DescriptionListCardKeyDirective,
    DescriptionListCardValueDirective,
    DirectSignupBannerComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    EmailInputComponent,
    EmailVerificationRegistrationComponent,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    HealthInformationReleaseFormComponent,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InviteFriendsModalComponent,
    IsErrorStatePipe,
    IsLoadingStatePipe,
    IsSuccessStatePipe,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    SidebarComponent,
    MomentPipe,
    NameInputComponent,
    NavbarComponent,
    NextRemoteVisitComponent,
    OfficeDetailsComponent,
    OmRecaptchaComponent,
    OmguiActionComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    OmguiSummaryRowComponent,
    OmguiSummaryRowExpandableContentDirective,
    OmguiSummaryRowIconDirective,
    OmguiSummaryRowPrimaryTextDirective,
    OmguiSummaryRowSecondaryTextDirective,
    OmguiSummaryRowTertiaryTextDirective,
    PaginationComponent,
    PasswordInputComponent,
    PencilComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubble,
    ProfileBubbleWithDetailsComponent,
    ProviderComponent,
    ProgramPromotionCardComponent,
    RadioInputNgrxComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    Spinner,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgActionItemComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgVirtualVisitComponent,
    SvgVitalsComponent,
    SvgCameraComponent,
    SvgCaretDownComponent,
    SvgCaretRightComponent,
    SvgCaretUpComponent,
    SvgCheckmarkComponent,
    SvgCopyIcon,
    SvgGermComponent,
    SvgDownloadAppComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgExclamationComponent,
    SvgFamilyComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInfoComponent,
    SvgLabResultComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgVaccineComponent,
    SvgStethoscopeComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TrackLinkDirective,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    XWithCircleComponent,
    ReferralBannerComponent,
    ModalTemplateComponent,
    DetectResizeDirective,
    ViewMoreComponent,
    DropdownFormComponent,
    OmguiBadgeComponent,
    RecommendationPreferenceIntakeComponent,
    RecommendationResultsComponent,
    LabelWithTooltipComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    EmailVerificationComponent,
  ],
})
export class SharedModule {}
