import includes from 'lodash-es/includes';

export enum Features {
  APPOINTMENT_SEARCH_ADDRESS_REMOTE_WEB = 'appointment_search_address_remote_web',
  APPOINTMENT_TYPE_HEADER_CONTENT_WEB = 'appointment_type_header_content_web',
  BILLING_PAYMENT_METHODS = 'billing_payment_methods',
  BHX_HOMESCREEN_INLINE_SURVEY = 'bhx_homescreen_inline_survey',
  BHX_MINDSET_SELF_BOOKING = 'bhx_mindset_self_book',
  BHX_MINDSET_HOMESCREEN_BANNER = 'bhx_mindset_homescreen_banner',
  BHX_LICENSURE_BASED_APPOINTMENT_SEARCH = 'bhx_licensure_based_appointment_search',
  BHX_PROGRAM_HOMESCREEN_BANNER = 'bhx_program_homescreen_banner',
  BHX_SHIFT_SPECIALTY_PROGRAMS_SELF_BOOK = 'bhx_shift_specialty_programs_self_book', // To be removed by https://jira.onemedical.com/browse/NS-245
  CONNECTED_DEVICES_FITBIT = 'connected-devices-fitbit',
  CORONAVIRUS_BANNER_ENABLED = 'coronavirus_banner_enabled',
  CORONAVIRUS_CARD_ENABLED = 'coronavirus_card_enabled',
  DISABLE_ENTERPRISE_PEDS_REGISTRATION = 'disable_enterprise_peds_registration',
  ACCOUNT_SETTINGS_CONNECTED_DEVICES = 'account-settings-connected-devices',
  INSURANCE_VERIFY_OR_SELF_PAY = 'insurance_verify_or_self_pay',
  KIDS_AND_FAMILY_ALLOWLISTED_14_PLUS_PEDS_REGISTRATION = 'kids_and_family_allowlisted_14_plus_peds_registration',
  PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION = 'pediatric_consumer_registration_promotion',
  PROACTIVE_CARE_ENABLE_POST_REGISTRATION_BOOKING = 'proactive_care_enable_post_registration_booking',
  SHARE_PCP = 'share_pcp',
  SHOW_UPCOMING_APPOINTMENT_REMINDER_CARD = 'show_upcoming_appointment_reminder_card_web',
  SHOW_VIRTUAL_DESCRIPTION_REGISTRATION = 'show_virtual_description_registration',
  VIRTUAL_CARE_REMOTE_VISITS_DEEP_LINK = 'virtual_care_remote_visits_deep_link',
  VIRTUAL_CARE_EMAIL_VERIFICATION_WEB = 'virtual_care_email_verification_web',
  NEW_SERVICES_MEMBER_APPOINTMENT_SERIES_BOOKING = 'member-appointment-series-booking',
}
export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}
