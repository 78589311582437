export type TileSelectionKey =
  | 'stressAnxiety'
  | 'medicationUpdates'
  | 'sleepIssues'
  | 'weightLoss'
  | 'achesPains'
  | 'other';

export interface TileSelectionOption {
  readonly title: string;
  readonly description: string;
  readonly icon: string;
  readonly key: TileSelectionKey;
  readonly useGenericHeading?: boolean; // If true, do not use the title for headings (and fall back to a generic value)
}

export const tileOptions: TileSelectionOption[] = [
  {
    title: 'Stress or Anxiety',
    key: 'stressAnxiety',
    description: 'Better coping for better health',
    icon: 'lotus-flower',
  },
  {
    title: 'Medication Updates',
    key: 'medicationUpdates',
    description: 'Renewals or changes',
    icon: 'pill-bottle',
  },
  {
    title: 'Sleep Issues',
    key: 'sleepIssues',
    description: 'Get better rest',
    icon: 'moon-with-stars',
  },
  {
    title: 'Weight Management',
    key: 'weightLoss',
    description: 'Health at any size',
    icon: 'weight-scale',
  },
  {
    title: 'Aches or Pains',
    key: 'achesPains',
    description: 'Get some relief',
    icon: 'first-aid-kit',
  },
  {
    title: 'Something Else',
    key: 'other',
    description: 'We can help with that too',
    icon: 'stethoscope',
    useGenericHeading: true,
  },
];

export const valuePropMapping: { [key in TileSelectionKey]: string[] } = {
  stressAnxiety: [
    'Assessment & goal planning',
    'Anxiety management skill training',
    'Prescriptions and/or referrals as needed',
  ],
  medicationUpdates: ['Review medications', 'Discuss alternatives & concerns', 'Align on medication plan'],
  sleepIssues: ['Sleep quality assessment', 'Options or testing', 'Personalized care plan'],
  weightLoss: ['Discuss goals & obstacles', 'Consider tests, prescriptions, or referrals', 'Personalized care plan'],
  achesPains: ['Review history and symptoms', 'Diagnostic testing or referrals', 'Personalized care plan'],
  other: ['Concern-based assessment', 'Diagnostic testing', 'Personalized care plan & follow up as needed'],
};
