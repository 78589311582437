import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';

@Component({
  selector: 'om-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class Login implements OnInit, OnDestroy {
  private consumerPromotionEnabled$ = this.featureFlagSelectors.getFeatureFlag(
    FeatureFlags.CONSUMER_REGISTRATION_UNIVERSAL_PROMOTIONAL_PRICING,
    false,
  );

  private destroy$ = new Subject();

  signInText: string;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.title.setTitle('Login');
    this.setSignInText();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.analyticsService.trackLoginPageView(params);
    });
  }

  setSignInText() {
    this.signInText = 'Please log in to your One Medical account';

    this.consumerPromotionEnabled$.pipe(takeUntil(this.destroy$)).subscribe(consumerPromoEnabled => {
      // note: this query param is only set on redirect from pediatric reg.
      const isFamilyPromo = this.activatedRoute.snapshot.queryParams.familyPromo === 'true';

      if (isFamilyPromo && consumerPromoEnabled) {
        // NOTE: the promotional code & amount should come from whatever is set to PROMO_DISCOUNT_CONSUMER_ADULT env var in onelife
        this.signInText =
          'Sign in to create an account for your kid at a special price of $99 for their first year (normally $199).';
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
