import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Observable } from 'rxjs';

import { StripeService } from './stripe.service';

@Component({
  selector: 'om-stripe-credit-card',
  templateUrl: './stripe-credit-card.component.html',
  styleUrls: ['./stripe-credit-card.component.scss'],
  providers: [
    StripeService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StripeCreditCardComponent),
    },
  ],
})
export class StripeCreditCardComponent implements AfterViewInit, ControlValueAccessor {
  static STYLE = {
    base: {
      fontSize: '16px',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: '300',
    },
  };

  @Input() externalErrors = false;
  @Input() showNicknameField = false;
  @Input() cardId?: string;

  nickname: string;

  private cardEntryElement: stripe.elements.Element;
  private propagateChange: (_: any) => {};
  private propagateError: (_: any) => {};
  private propagateComplete: (_: any) => {};

  constructor(private stripeService: StripeService) {}

  ngAfterViewInit() {
    this.cardEntryElement = this.stripeService.createElement('card', {
      style: StripeCreditCardComponent.STYLE,
    });

    this.cardEntryElement.mount(this.cardId ? `#card-element-${this.cardId}` : '#card-element');

    this.cardEntryElement.on('ready', (e => this.cardEntryElement.focus()).bind(this));

    this.cardEntryElement.on(
      'change',
      ((e: stripe.elements.ElementChangeResponse) => {
        if (this.propagateChange) {
          this.propagateChange(e.value);
        }
        if (this.propagateComplete && e.complete) {
          this.propagateComplete(e);
        }
        if (this.propagateError && e.error) {
          this.propagateError(e);
        }
      }).bind(this),
    );
  }

  writeValue(value: any) {
    if (!this.cardEntryElement) {
      return;
    }
    this.cardEntryElement.update({ value: value });
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnError(fn) {
    this.propagateError = fn;
  }

  registerOnComplete(fn) {
    this.propagateComplete = fn;
  }

  registerOnTouched(fn) {}

  generateToken(): Observable<stripe.Token> {
    const tokenResponse = new Subject<stripe.Token>();
    this.stripeService.createToken(this.cardEntryElement).then((response: stripe.TokenResponse) => {
      if (response.error) {
        tokenResponse.error(response.error.message);
      } else {
        tokenResponse.next(response.token);
      }
    });
    return tokenResponse.asObservable();
  }

  confirmSetupIntent(setupIntentClientSecret): Observable<stripe.setupIntents.SetupIntent> {
    const setupIntentResponse = new Subject<stripe.setupIntents.SetupIntent>();
    this.stripeService
      .confirmCardSetup(setupIntentClientSecret, this.cardEntryElement)
      .then((response: stripe.SetupIntentResponse) => {
        if (response.error) {
          setupIntentResponse.error(response.error.message);
        } else {
          setupIntentResponse.next(response.setupIntent);
        }
      });
    return setupIntentResponse.asObservable();
  }
}
