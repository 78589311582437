import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { MODULE_EMAIL_VERIFICATION_PAGE } from '@app/core/mixpanel.constants';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { EmailVerificationStatus } from '@app/shared/email-verification-status';
import {
  EmailVerificationAction,
  EmailVerificationService,
  EmailVerificationSource,
} from '@app/shared/email-verification.service';
import { ToastService } from '@app/shared/toast.service';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { EmailVerificationStepComponent } from './email-verification-step.component';

@Injectable()
export class EmailVerificationConfig extends RegistrationStep {
  GA_LABEL = 'EmailVerification_Step';
  MODULE = MODULE_EMAIL_VERIFICATION_PAGE;
  ACCOUNT_LOCKED_MODULE_VARIANT = 'Account Locked Error State Page';

  accountLocked = false;
  component = EmailVerificationStepComponent;
  componentInstance;
  progress = 80;
  form: FormGroup = this.formBuilder.group({
    verificationCode: ['', Validators.required],
    skip: false,
  });

  constructor(
    private attemptedPathService: AttemptedPathService,
    private emailVerificationService: EmailVerificationService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
  ) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: EmailVerificationStepComponent, state: EnterpriseRegistration) {
    this.emailVerificationService.refreshVerificationStatus();
    this.componentInstance = component;
    component.form = this.form;
    component.preferredEmail = state.preferredEmail;
    component.resendEmail.subscribe(() => {
      this.trackVerificationAction(EmailVerificationAction.resend, state);
      this.createCodeAndSendEmail().subscribe(codeCreated => {
        if (codeCreated) {
          this.toastService.push('Verification email has been sent');
          this.accountLocked = false;
        } else {
          this.toastService.push('Unable to resend verification email');
        }
      });
    });
    component.emailTechSupport.subscribe(() => {
      this.trackVerificationAction(EmailVerificationAction.techSupport, state);
    });
    this.trackPageView();
    this.createCodeAndSendEmail().subscribe(codeCreated => {
      if (!codeCreated) {
        this.toastService.push('Unable to send verification email, please skip for now');
      }
    });
    state.successPageSource = this.MODULE;
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    if (this.form.value.skip) {
      this.trackVerificationAction(EmailVerificationAction.skip, state);
      this.navigateToNextStep(state);
      return observableOf(true);
    }
    return this.emailVerificationService.submitVerificationCode(this.form.value.verificationCode).pipe(
      map((status: EmailVerificationStatus) => {
        if (status.errorMessage) {
          this.trackEmailVerification(state, false);
          if (status.accountLocked) {
            this.accountLocked = true;
            this.trackAccountLocked(state);
          }
          throw new Error(status.errorMessage);
        }

        this.trackVerificationAction(EmailVerificationAction.continue, state);
        this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
          module: this.MODULE,
          isWhitelist: state.isWhitelisted,
        });
        this.trackEmailVerification(state, true);
        this.navigateToNextStep(state);
        return true;
      }),
    );
  }

  private navigateToNextStep(state: EnterpriseRegistration) {
    if (this.attemptedPathService.hasAttemptedPath()) {
      this.attemptedPathService.navigateToAttemptedPath();
      return false;
    } else {
      state.setCurrentStep(StepName.success);
    }
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }

  private createCodeAndSendEmail(): Observable<boolean> {
    return this.emailVerificationService
      .createVerificationCode(EmailVerificationSource.registration)
      .pipe(map(status => status.codeSent));
  }

  private trackVerificationAction(action: EmailVerificationAction, state: EnterpriseRegistration) {
    const props = {
      module: this.MODULE,
      isWhitelist: state.isWhitelisted,
      moduleVariant: undefined,
    };
    if (this.accountLocked) {
      props.moduleVariant = this.ACCOUNT_LOCKED_MODULE_VARIANT;
    }
    this.enterpriseRegistrationAnalyticsService.emailVerificationAction(action, props);
  }

  private trackEmailVerification(state: EnterpriseRegistration, verified: boolean) {
    this.enterpriseRegistrationAnalyticsService.emailVerification(
      {
        module: this.MODULE,
        isWhitelist: state.isWhitelisted,
      },
      verified,
    );
  }

  private trackAccountLocked(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.accountLocked({
      module: this.MODULE,
      isWhitelist: state.isWhitelisted,
      moduleVariant: this.ACCOUNT_LOCKED_MODULE_VARIANT,
    });
  }
}
