import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class PatientPreferencesGraphqlService extends Query<any> {
  document = gql`
    query PatientPreferencesAndAppointments {
      patient {
        id
        healthkitAuthorized
        fitbitAuthorized
        upcomingAppointmentsForConnectedDeviceDiscussion {
          nodes {
            startAt
          }
        }
        mostRecentHealthkitMeasurement {
          id
          collected_at
        }
        mostRecentFitbitMeasurement {
          id
          collected_at
        }
        preferences {
          id
          connectedDevicesOptIn
        }
      }
    }
  `;
}
