import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { MODULE_CONCIERGE_PAGE } from '@app/core/mixpanel.constants';
import { WindowService } from '@app/core/window.service';
import { RoutingGroupOption } from '@app/shared';
import { Experiments, PatientExperimentService } from '@app/shared/patient-experiment.service';

import { RegistrationStepDirective } from '../registration-step.directive';

enum OptionValue {
  chooseProvider = 'choose-provider',
  healthHistory = 'health-history',
  requestMeds = 'request-meds',
}

export const chooseYourProvider: RoutingGroupOption<OptionValue> = {
  header: 'Choose your provider',
  description: 'Select a nearby primary care provider who’s right for you',
  value: OptionValue.chooseProvider,
  iconUrl: 'assets/images/icons/registration/provider.svg',
};

export const healthHistory: RoutingGroupOption<OptionValue> = {
  header: 'Add your health history',
  description: 'Share your health history to personalize your experience',
  value: OptionValue.healthHistory,
  iconUrl: 'assets/images/icons/registration/chart.svg',
};

export const requestMedication: RoutingGroupOption<OptionValue> = {
  header: 'Request a new medication',
  description: 'Refill or request a prescription in a few taps',
  value: OptionValue.requestMeds,
  iconUrl: 'assets/images/icons/registration/medication.svg',
  badgeText: 'mobile app',
};

export const conciergeDefaultOptions: RoutingGroupOption<OptionValue>[] = [
  chooseYourProvider,
  healthHistory,
  requestMedication,
];

export const conciergeProviderOrderExperimentOptions: RoutingGroupOption<OptionValue>[] = [
  healthHistory,
  chooseYourProvider,
  requestMedication,
];

@Component({
  selector: 'concierge-step',
  templateUrl: './concierge-step.component.html',
  styleUrls: ['./concierge-step.component.scss'],
})
export class ConciergeStepComponent extends RegistrationStepDirective implements OnInit {
  @Input() healthIntakeSurveyId: string;

  readonly optionsControl = new FormControl();
  options$: Observable<RoutingGroupOption<OptionValue>[]>;
  newPcpSelection = false;

  constructor(
    private router: Router,
    private links: LinksService,
    private windowService: WindowService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private patientExperimentService: PatientExperimentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.options$ = this.patientExperimentService
      .isVariantEnabled$(
        Experiments.CONCIERGE_PROVIDER_ORDER_EXPERIMENT_NAME,
        Experiments.CONCIERGE_PROVIDER_ORDER_EXPERIMENT_VARIANT_NAME,
      )
      .pipe(map(enabled => (enabled ? conciergeProviderOrderExperimentOptions : conciergeDefaultOptions)));

    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.NEW_PCP_SELECTION, false)
      .subscribe(on => (this.newPcpSelection = on));

    this.optionsControl.valueChanges.pipe(take(1)).subscribe({
      next: (option: OptionValue) => {
        if (option === OptionValue.chooseProvider) {
          this.newPcpSelection
            ? this.router.navigate([this.links.selectProvider], { queryParams: { source: MODULE_CONCIERGE_PAGE } })
            : this.windowService.redirect(this.links.onelife.choosePcp + `?source=Concierge Page`);
        } else if (option === OptionValue.healthHistory) {
          this.router.navigate([this.links.healthSurveyLink(this.healthIntakeSurveyId)], {
            queryParams: { source: MODULE_CONCIERGE_PAGE },
          });
        } else if (option === OptionValue.requestMeds) {
          this.router.navigate([this.links.home], {
            queryParams: { action: 'show-treat-me-now', source: MODULE_CONCIERGE_PAGE },
          });
        }
      },
    });
  }

  skip() {
    this.router.navigate([this.links.home], { queryParams: { source: MODULE_CONCIERGE_PAGE } });
  }
}
