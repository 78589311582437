import first from 'lodash-es/first';
import keys from 'lodash-es/keys';
import moment from 'moment-timezone';

import { Office } from '../shared/office';
import { Provider } from '../shared/provider';

export class AppointmentInventory {
  start_time: moment.Moment;
  timezone: string;
  duration: number;
  date: string;
  id: number;

  static fromApiV2(response): AppointmentInventory {
    const apptInv = new AppointmentInventory();
    // Sets the appointment start datetime in the timezone of the Appointment's service area
    apptInv.start_time = moment(response['utc_start_datetime']).utc().tz(response['timezone_tzinfo']);
    apptInv.timezone = response['timezone_tzinfo'];
    apptInv.duration = response['duration'];
    apptInv.id = response['id'];
    apptInv.date = response['date'];
    return apptInv;
  }

  static fromGraphQL(response): AppointmentInventory {
    const apptInv = new AppointmentInventory();

    apptInv.start_time = moment(response['utcStartDatetime']).utc().tz(response['timezoneTzinfo']);
    apptInv.timezone = response['timezoneTzinfo'];
    apptInv.duration = response['duration'];
    apptInv.date = response['date'];
    apptInv.id = response['id'];
    return apptInv;
  }
}

export interface DayInventories {
  [date: string]: AppointmentInventory[];
}
export class ProviderInventories {
  provider: Provider;
  office: Office;
  dayInventories: DayInventories;
  nextInventory: AppointmentInventory;
  hasInventories: boolean;

  static fromApiV2(response): ProviderInventories {
    const provInv = new ProviderInventories();
    provInv.office = Office.fromApiV2(response['office']);
    provInv.provider = Provider.fromApiV2(response['provider']);
    provInv.nextInventory = AppointmentInventory.fromApiV2(response['next_inventory']);
    provInv.dayInventories = {};
    const inventoriesByDate = response['appointment_inventories_by_date'];
    provInv.hasInventories = inventoriesByDate && keys(inventoriesByDate).length > 0;
    keys(inventoriesByDate).forEach(date => {
      provInv.dayInventories[moment(date).format('YYYY-MM-DD')] = inventoriesByDate[date].map(dayInv =>
        AppointmentInventory.fromApiV2(dayInv),
      );
    });
    return provInv;
  }
}

// These two utilty functions were originally part of next-remote-visit component
// and were extracted to share the logic in other areas (i.e. for analytics events)
// returns a YYYY-MM-DD formatted datestring
export function firstInventoryDateAvailable(inventories: DayInventories): string {
  return first(Object.keys(inventories));
}

export function getFirstDateInventories(inventories: DayInventories): AppointmentInventory[] {
  const firstDate = firstInventoryDateAvailable(inventories);
  return Object.values(inventories[firstDate]);
}
