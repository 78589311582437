<div class="container h-100 p-3 pt-lg-10 pb-md-4" data-cy="appointment-recommendation">
  <om-recommendation-preference-intake
    *ngIf="remoteRecommendation && !showResults && !showLoading"
    [tileOptions]="tileOptions"
    (multipleOptionsSelected)="multipleOptionsSelected($event)"
    (continue)="selectIssue($event)"
    (componentLoaded)="onPreferenceIntakeLoaded()"
    (skipped)="submit.emit()"
  >
  </om-recommendation-preference-intake>
  <om-loading-spinner *ngIf="showLoading" message="Determining how we can best help you"></om-loading-spinner>
  <om-recommendation-results
    *ngIf="showResults && !showLoading"
    [valuePropMapping]="valuePropMapping"
    [selectedOption]="selectedOption"
    [remoteRecommendation]="remoteRecommendation"
    [patientName]="patientPreferredName"
    (componentLoaded)="onRecommendationResultsLoaded($event)"
  ></om-recommendation-results>
</div>
