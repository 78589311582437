import { Inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import Rollbar from 'rollbar';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { RollbarService } from '../core/rollbar.service';
import { StorageService } from '../core/storage.service';
import {
  AnonymousFlag,
  AnonymousFlagDataGraphQL,
  AnonymousFlagDataResponse,
} from './anonymous-flag-data-graphql.service';

export enum AnonymousFlagName {
  AUTH0_WEB = 'auth0_web',
}

@Injectable()
export class AnonymousFlagDataService {
  STORAGE_KEY = 'anonymous_flag_client_token';
  _flags: { name: string; enabled: boolean }[];

  constructor(
    private anonymousFlagDataGraphQL: AnonymousFlagDataGraphQL,
    private storageService: StorageService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  clientToken = this.storageService.getItem(this.STORAGE_KEY);

  get flags$(): Observable<AnonymousFlag[]> {
    if (this._flags) {
      return observableOf(this._flags);
    }
    return this._fetchFlags();
  }

  enabledByName$(name: string) {
    return this.flags$.pipe(
      map(flags => flags.find(item => item.name === name)),
      map(item => (item ? item.enabled : false)),
    );
  }

  private _handleNewClientToken(token) {
    if (!token) {
      return;
    }
    this.storageService.setItem(this.STORAGE_KEY, token);
    this.clientToken = token;
  }

  private _fetchFlags() {
    const req = this.anonymousFlagDataGraphQL.fetch({ clientToken: this.clientToken }).pipe(
      map((result: ApolloQueryResult<AnonymousFlagDataResponse>) => {
        this._handleNewClientToken(result?.data?.anonymousFlagData?.clientToken);
        this._flags = result.data.anonymousFlagData.flags;
        return this._flags;
      }),
    );
    req.subscribe({
      error: ({ message }) => {
        this.rollbar.error(`AnonymousFlagDataService GraphQL error: ${message}`);
        this._flags = [];
        return this._flags;
      },
    });
    return req;
  }
}
