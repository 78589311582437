import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { MODULE_CHOOSE_RELATIONSHIP_TYPE_PAGE } from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { Features } from '@app/shared/active-feature-flag-collection';
import { FeatureFlagByB2bIDGraphQL } from '@app/shared/feature-flag-graphql.service';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { MembershipType } from '../membership-type';
import {
  AllDependentsRegisteredError,
  AlreadyRegisteredError,
  INVALID_CONVERSION_ERROR_MESSAGE,
  InvalidConversionError,
} from '../whitelisted-employee-errors';
import { MembershipSelectionStepComponent } from './membership-selection-step.component';

@Injectable()
export class MembershipSelectionConfig extends RegistrationStep {
  GA_LABEL = 'Who_Membership_For_Step';
  MODULE = MODULE_CHOOSE_RELATIONSHIP_TYPE_PAGE;
  SYSTEM_ERROR_MESSAGE = 'There was an error converting your membership. Please try again later.';
  component = MembershipSelectionStepComponent;
  progress = 20;
  componentInstance;
  showLoginLink = true;

  form: FormGroup = this.formBuilder.group({
    membershipType: ['', Validators.required],
  });

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private featureFlagByB2bIDGraphQL: FeatureFlagByB2bIDGraphQL,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  initComponent(component: MembershipSelectionStepComponent, state: EnterpriseRegistration) {
    component.form = this.form;
    this.addPedsIfAvailable(component, state);
    this.componentInstance = component;
    this.trackPageView();
  }

  trackPageVisit(state: any, enterpriseRegistrationAnalyticsService: any) {
    // No-op: We handle this specially based on feature flags
  }

  patchParams(params: { [k: string]: any }) {
    if (params.membershipType) {
      this.form.patchValue({ membershipType: params.membershipType });
    }
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (!this.form.valid) {
      return observableThrowError(new Error());
    }

    state.details.membershipType = this.form.value.membershipType;
    if (this.shouldGetActivationCodeFromDependent(state)) {
      // the company does not verify dependents against the e-list, so allow this dependent to register with an activation code
      return this.redirectToActivationCode(state);
    }
    if (this.authService.isLoggedIn() && state.userIsComplete()) {
      return this.submitAccountConversion(state, captcha);
    } else {
      return this.submitNewAccount(state);
    }
  }

  private submitAccountConversion(state: EnterpriseRegistration, captcha: any): Observable<any> {
    return state.submitAccountConversion(this.userService, captcha, true).pipe(
      catchError((error: Error) => {
        this.componentInstance.hasError = true;
        if ((error as InvalidConversionError).invalidConversion) {
          this.componentInstance.errorMessage = INVALID_CONVERSION_ERROR_MESSAGE;
        } else {
          this.componentInstance.errorMessage = this.SYSTEM_ERROR_MESSAGE;
        }
        this.form.patchValue({ membershipType: '' });
        this.trackError('enterpriseConversionError');
        return observableThrowError(error);
      }),
    );
  }

  private submitNewAccount(state: EnterpriseRegistration): Observable<any> {
    if (this.invalidDependentSelection(state)) {
      // the company e-lists dependents, but there are no unregistered dependents for the employee
      this.form.patchValue({ membershipType: '' });
      return observableThrowError(new AllDependentsRegisteredError());
    }
    if (this.personalAlreadyRegistered(state)) {
      this.form.patchValue({ membershipType: '' });
      return observableThrowError(new AlreadyRegisteredError());
    }
    this.trackSelection(state);
    if (this.form.value.membershipType === MembershipType.KIDS) {
      this.router.navigate(['/registration/pediatric']);
      return observableOf(false);
    }

    state.setCurrentStep(StepName.accountSetUp);
    return observableOf(true);
  }

  private addPedsIfAvailable(component: MembershipSelectionStepComponent, state: EnterpriseRegistration) {
    const enterprisePedsRefDisabled$ = this.featureFlagByB2bIDGraphQL.watch({
      name: Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION,
      id: state.b2bCompanyId,
    }).valueChanges;

    enterprisePedsRefDisabled$.pipe(take(1)).subscribe(
      result => {
        // Hide pediatric option if the flag is disabled, or an existing member is being converted.
        component.pediatricFeatureEnabled =
          !result.data.featureFlag.enabledForCompany && !this.authService.isLoggedIn();

        this.enterpriseRegistrationAnalyticsService.membershipSelectionStepViewed({
          isWhitelist: state.isWhitelisted,
          isLoggedIn: false,
          companyId: state.b2bCompanyId,
          isPedsShown: component.pediatricFeatureEnabled,
          module: this.MODULE,
        });

        if (component.pediatricFeatureEnabled) {
          component.options.push(component.CHILD_MEMBERSHIP_OPTION);
        }
      },
      error => {
        // TODO: Log to SumoLogic
        console.error(error);
      },
    );
  }

  private redirectToActivationCode(state: EnterpriseRegistration) {
    const activationCode = state.getStep(StepName.activationCode);
    activationCode.intendedNextStep = StepName.accountSetUp;
    activationCode.progress = 25; // halfway between this and accountSetUp
    state.setCurrentStep(StepName.activationCode);
    this.trackSelection(state);
    return observableOf(true);
  }

  private trackSelection(state: EnterpriseRegistration) {
    switch (this.form.value.membershipType) {
      case MembershipType.PERSONAL:
        this.enterpriseRegistrationAnalyticsService.myBenefitSelected({
          isWhitelist: state.isWhitelisted,
          module: this.MODULE,
        });
        break;
      case MembershipType.SPOUSE:
        this.enterpriseRegistrationAnalyticsService.spouseBenefitSelected({
          isWhitelist: state.isWhitelisted,
          module: this.MODULE,
        });
        break;
      case MembershipType.KIDS:
        this.enterpriseRegistrationAnalyticsService.childBenefitSelected({
          isWhitelist: state.isWhitelisted,
          isLoggedIn: this.authService.isLoggedIn(),
          module: this.MODULE,
          companyId: state.b2bCompanyId,
        });
        break;
    }
  }

  private personalAlreadyRegistered(state: EnterpriseRegistration): boolean {
    return state.whitelistedEmployeeRegistered() && this.form.value.membershipType === MembershipType.PERSONAL;
  }

  private invalidDependentSelection(state: EnterpriseRegistration): boolean {
    return (
      state.isWhitelisted && state.isAdultDependent && state.verifyDependentInfo() && !state.hasUnregisteredDependents()
    );
  }

  private shouldGetActivationCodeFromDependent(state: EnterpriseRegistration): boolean {
    return state.isWhitelisted && state.isAdultDependent && !state.verifyDependentInfo();
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }

  private trackError(message, module = this.MODULE) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error: message,
      formField: 'Membership Selection',
      module,
    });
  }
}
