<div
  class="profile-bubble"
  [class.extra-small]="size == 'extra-small'"
  [class.small]="size == 'small'"
  [class.medium-small]="size == 'medium-small'"
  [class.medium]="size == 'medium'"
  [class.large]="size == 'large'"
  [class.extra-large]="size == 'extra-large'"
  [class.extra-extra-large]="size == 'extra-extra-large'"
>
  <div [hidden]="status !== profileBubbleStates.DANGER">
    <span *ngIf="size == 'extra-small'" class="decorator">
      <svg>
        <circle class="danger" cx="21" cy="21" r="19"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'small'" class="decorator">
      <svg>
        <circle class="danger" cx="26" cy="26" r="24"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'medium-small'" class="decorator">
      <svg>
        <circle class="danger" cx="31" cy="31" r="29"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'medium'" class="decorator">
      <svg>
        <circle class="danger" cx="51" cy="51" r="49"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'large'" class="decorator">
      <svg>
        <circle class="danger" cx="68.5" cy="68.5" r="66.5"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'extra-large'" class="decorator">
      <svg>
        <circle class="danger" cx="93.5" cy="93.5" r="91.5"></circle>
      </svg>
    </span>
    <img src="assets/images/icons/error.svg" class="danger-icon" alt="" />
  </div>
  <div [hidden]="status !== profileBubbleStates.PROCESSING">
    <om-spinner class="decorator" [size]="size"></om-spinner>
  </div>
  <div [hidden]="status !== profileBubbleStates.REMOTE">
    <om-svg-camera class="decorator" [size]="size"></om-svg-camera>
  </div>
  <div class="bubble combo-{{ colorCombo }}">
    <div [ngStyle]="profileImageStyles()" class="profile-image" *ngIf="source"></div>
    <img class="profile-image hidden" [src]="source" *ngIf="source" (error)="fallbackToInitials($event)" alt="" />
    <div class="initials" [hidden]="source">{{ initials }}</div>
    <div class="shadow"></div>
  </div>
</div>
