import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';

import { State } from '@app/app.reducer';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { ANALYTICS_TIMESTAMP_FORMAT } from '@app/core/analytics.service';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';
import { ConfigService } from '@app/core/config.service';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipStatus } from '@app/core/membership';
import {
  ISSUE_REFINEMENT_PAGE,
  ISSUE_SELECTION_PAGE,
  MP_EVENT_EMAIL_UNVERIFIED,
  MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
  MP_EVENT_EMAIL_VERIFICATION_ACTION,
  MP_EVENT_EMAIL_VERIFIED,
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_REG_INPUT_SUBMITTED,
  MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
  MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE,
  MODULE_ACCOUNT_SETUP_PAGE,
  MODULE_COMPANY_CODE_PAGE,
  MODULE_SERVICE_AREA_PAGE,
  MODULE_CONCIERGE_PAGE,
  MODULE_SCHEDULE_VISIT_PAGE,
  MODULE_WORK_EMAIL_VALIDATION_PAGE,
  MODULE_DIRECT_SIGN_UP_FAMILY_PAGE,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { EmailVerificationAction } from '@app/shared/email-verification.service';

export interface RegInputErroredProperties {
  error: string;
  formField: string;
  isWhitelist?: boolean;
  module: string;
}

export interface RegInputSubmittedProperties {
  isWhitelist?: boolean;
  isLoggedIn?: boolean;
  isPedsShown?: boolean;
  companyId?: number;
  serviceArea?: string;
  companyCode?: string;
  module: string;
  moduleVariant?: string;
}

export interface BasicEventProperties {
  module: string;
  moduleVariant?: string;
  isWhitelist?: boolean;
  source?: string;
  service_area?: string;
}

declare const ga: any;
declare const fbq: any;
@Injectable({
  providedIn: 'root',
})
export class EnterpriseRegistrationAnalyticsService extends AppointmentAnalyticsBaseService {
  static FLOW = 'Registration';
  static OMNOWFLOW = 'OM Now Navigation';
  static POST_REGISTRATION_FLOW = 'Post Registration';
  static DIRECT_SIGNUP_FLOW = 'Direct Sign Up';
  static MEMBERSHIP_TYPE = 'Enterprise';
  static VERSION = 'Enterprise Registration Q12020';

  commonPostRegAppointmentFields = {
    flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
    module: 'Appointment Confirmation Page',
    om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    membership_status: MembershipStatus.CURRENT,
    source: 'Schedule Visit Page',
    ...this.bookingState?.trackableProperties,
    ...this.searchState?.trackableProperties,
  };

  postRegSelectedIssue: string;

  constructor(
    config: ConfigService,
    store: Store<State>,
    mixpanel: MixpanelService,
    launchDarkly: LaunchDarklyService,
  ) {
    super(mixpanel, store, launchDarkly);

    if (config.json.gaAccount) {
      ga('create', config.json.gaAccount, 'auto');
      ga('send', 'pageview', location.pathname);
    }

    if (config.json.facebookAccount) {
      fbq('init', config.json.facebookAccount);
      fbq('track', 'PageView');

      fbq.push(['addPixelId', config.json.facebookAccount]);
      fbq.push(['track', 'PixelInitialized', {}]);
    }

    if (config.json.linkedinPartnerId) {
      (<any>window)._linkedin_data_partner_id = config.json.linkedinPartnerId;
      (function () {
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode.insertBefore(b, s);
      })();
    }
  }

  regInputErrored(props: RegInputErroredProperties) {
    return this.trackWithDefaultProperties('Input Error Encountered', {
      error: props.error,
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      form_field: props.formField,
      is_whitelist: props.isWhitelist,
      module: props.module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  regInputSubmitted({
    serviceArea: service_area,
    isWhitelist: is_whitelist,
    module,
    companyCode: company_code,
    moduleVariant: module_variant,
  }: RegInputSubmittedProperties) {
    const trackingParams = pickBy(
      {
        flow: EnterpriseRegistrationAnalyticsService.FLOW,
        flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
        is_whitelist,
        module,
        om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        service_area,
        company_code,
        module_variant,
      },
      v => !isNil(v),
    );

    return this.trackWithDefaultProperties(MP_EVENT_REG_INPUT_SUBMITTED, trackingParams);
  }

  bookAppointmentClicked(inventory: AppointmentInventory) {
    const trackProps = {
      ...this.commonPostRegAppointmentFields,
      appointment_inventory_id: inventory.id,
      selected_date: inventory.start_time.format(ANALYTICS_TIMESTAMP_FORMAT),
    };
    return super.trackWithBookingAndSearchStates('Book Appointment Clicked', trackProps);
  }

  appointmentBooked(appointmentId: number, inventoryId: number) {
    const trackProps = {
      ...this.commonPostRegAppointmentFields,
      appointment_id: appointmentId,
      appointment_inventory_id: inventoryId,
    };

    return super.trackWithBookingAndSearchStates('Appointment Booked', trackProps);
  }

  seeMoreAppointmentsClicked(inventoryCount: number, properties: { serviceArea: string }) {
    const trackProps = {
      ...this.commonPostRegAppointmentFields,
      module: 'Schedule Visit Page',
      appointment_type: 'Remote Visit',
      service_area: properties.serviceArea,
      inventory_count: inventoryCount,
    };

    return super.trackWithBookingAndSearchStates('See More Appointments Clicked', trackProps);
  }

  bookingFailed() {
    return super.trackWithBookingAndSearchStates('Appointment Booking Failed', this.commonPostRegAppointmentFields);
  }

  bookingCancelled() {
    return super.trackWithBookingAndSearchStates('Appointment Booking Cancelled', this.commonPostRegAppointmentFields);
  }

  rescheduled() {
    return super.trackWithBookingAndSearchStates('Appointment Rescheduled', this.commonPostRegAppointmentFields);
  }

  sendCompanyCodeViewed() {
    return this.trackWithDefaultProperties('Send Company Code Viewed', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: false,
      module: MODULE_COMPANY_CODE_PAGE,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  enterActivationCodeClicked({ isWhitelist, serviceArea }) {
    return this.trackWithDefaultProperties('Enter Activation Code Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_WORK_EMAIL_VALIDATION_PAGE,
      submodule: 'Email Error Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: isWhitelist,
      service_area: serviceArea,
    });
  }

  emailErrorModalViewed({ isWhitelist, serviceArea }) {
    return this.trackWithDefaultProperties('Email Error Modal Viewed', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_WORK_EMAIL_VALIDATION_PAGE,
      submodule: 'Email Error Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: isWhitelist,
      service_area: serviceArea,
    });
  }

  emailErrorModalClosed({ isWhitelist, serviceArea }) {
    return this.trackWithDefaultProperties('Email Error Modal Closed', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_WORK_EMAIL_VALIDATION_PAGE,
      submodule: 'Email Error Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: isWhitelist,
      service_area: serviceArea,
    });
  }

  pageViewed(props: BasicEventProperties) {
    return this.trackBasicEvent(MP_EVENT_PAGE_VIEWED, props);
  }

  loginClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Login Clicked', props);
  }

  redirectToHomeClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Continue Flow Clicked', props);
  }

  redirectToConsentFormClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Complete Consent Form Clicked', props);
  }

  membershipSelectionStepViewed(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, isPedsShown, companyId, module } = props;

    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      is_peds_shown: isPedsShown,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  myBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module } = props;
    return this.trackWithDefaultProperties('My Benefit Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  spouseBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module } = props;
    return this.trackWithDefaultProperties('Spouse/Dependent Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  childBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, companyId, module } = props;
    return this.trackWithDefaultProperties('Child Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  invalidAddressSelected(error: string, isWhitelist: boolean) {
    return this.trackWithDefaultProperties('Invalid Address Selected', {
      error,
      flow: 'Registration',
      form_field: 'Address',
      is_whitelist: isWhitelist,
      module: MODULE_ACCOUNT_SETUP_PAGE,
      om_membership_type: 'Enterprise',
    });
  }

  continueAsAVirtualMemberClicked() {
    return this.trackWithDefaultProperties('Continue As Virtual Member Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.OMNOWFLOW,
      module: MODULE_SERVICE_AREA_PAGE,
      submodule: 'Virtual Care Modal',
    });
  }

  directSignupStartedPostRegistration() {
    return this.trackWithDefaultProperties('Direct Sign Up Started', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Enterprise Registration Confirmation Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
      is_logged_in: true,
    });
  }

  directSignupTypeSelected(type: string) {
    return this.trackWithDefaultProperties('Direct Sign Up Type Selected', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
      direct_sign_up_type: type,
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
      is_pediatrics: type === 'Child',
    });
  }

  directSignupSelectionPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
      is_whitelist: this.isWhitelist,
      is_pediatrics: true,
    });
  }

  directSignupFamilyPageViewed(source: string) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_FAMILY_PAGE,
      membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
      source,
    });
  }

  adultDirectSignupPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupSubmitted() {
    return this.trackWithDefaultProperties('Direct Sign Up Submitted', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupCanceled() {
    return this.trackWithDefaultProperties('Direct Sign Up Canceled', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  trackAnotherDependentClicked() {
    return this.trackWithDefaultProperties('Register Another Dependent Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupGenderDetailsExpanded() {
    return this.trackWithDefaultProperties('Show Gender Details Expanded', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupConfirmationPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  trackScheduleVisitPageViewed(props) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
      module: 'Schedule Visit Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      membership_status: MembershipStatus.CURRENT,
      source: props.source,
      service_area: props?.serviceArea || this.defaultProperties.service_area,
      is_whitelist: props?.isWhitelist || this.isWhitelist,
      inventory_count: props.inventoryCount,
      ...(this.postRegSelectedIssue && { issue_selected: this.postRegSelectedIssue }),
    });
  }

  inventorySelected(inventory: AppointmentInventory, props?: any) {
    return this.trackWithDefaultProperties('Appointment Selected', {
      flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
      module: 'Schedule Visit Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      membership_status: MembershipStatus.CURRENT,
      service_area: props.serviceArea,
      appointment_type: 'Remote Visit',
      appointment_inventory_id: inventory.id,
      selected_date: inventory.start_time.format(ANALYTICS_TIMESTAMP_FORMAT),
      ...(this.postRegSelectedIssue && { issue_selected: this.postRegSelectedIssue }),
    });
  }

  emailVerification(props, verified: boolean) {
    if (verified) {
      return this.trackBasicEvent(MP_EVENT_EMAIL_VERIFIED, props);
    } else {
      return this.trackBasicEvent(MP_EVENT_EMAIL_UNVERIFIED, props);
    }
  }

  emailVerificationAction(actionSelected: EmailVerificationAction, props) {
    return this.trackWithDefaultProperties(
      MP_EVENT_EMAIL_VERIFICATION_ACTION,
      pickBy(
        {
          flow: this.flowForModule(props.module),
          flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
          is_whitelist: props.isWhitelist,
          module: props.module,
          module_variant: props.moduleVariant,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
          action_selected: actionSelected,
        },
        v => !isNil(v),
      ),
    );
  }

  accountLocked(props: BasicEventProperties) {
    return this.trackWithDefaultProperties(
      MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
      pickBy(
        {
          flow: this.flowForModule(props.module),
          flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
          is_whitelist: props.isWhitelist,
          module: props.module,
          module_variant: props.moduleVariant,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        },
        v => !isNil(v),
      ),
    );
  }

  trackGoogleEvent(action: string, label: string) {
    ga('send', 'event', 'EntSignupForm', action, label, null);
  }

  trackFacebookEvent(action: string) {
    fbq('track', action);
  }

  setIssueSelected(postRegSelectedIssue: string) {
    this.postRegSelectedIssue = postRegSelectedIssue;
  }

  trackIssueSelectionPageViewed(props) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
      module: ISSUE_SELECTION_PAGE,
      source: props.source,
      membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
    });
  }

  trackIssueRefinementPagePageViewed(props) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
      module: ISSUE_REFINEMENT_PAGE,
      membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      source: props.source,
      issue_selected_count: props.issuesSelected.length,
      issue_selected_list: props.issuesSelected,
    });
  }

  trackPostRegRecInterstitialPageViewed(props) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW,
      module: 'Interstitial Page',
      membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      source: props.source,
      issue_selected: props.issueSelected,
    });
  }

  private trackBasicEvent(eventName: string, props: BasicEventProperties) {
    return this.trackWithDefaultProperties(
      eventName,
      pickBy(
        {
          flow: this.flowForModule(props.module),
          flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
          is_whitelist: props.isWhitelist,
          module: props.module,
          module_variant: props.moduleVariant,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
          om_membership_status: MembershipStatus.CURRENT,
          source: props.source,
          service_area: props.service_area,
        },
        v => !isNil(v),
      ),
    );
  }

  private flowForModule(moduleName: string) {
    if (moduleName === MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE || moduleName === MODULE_CONCIERGE_PAGE) {
      return EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW;
    }

    return EnterpriseRegistrationAnalyticsService.FLOW;
  }
}
