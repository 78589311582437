import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppointmentType } from '@app/appointment/appointment-type';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { LinksService } from '@app/core/links.service';
import { MODULE_SCHEDULE_VISIT_PAGE } from '@app/core/mixpanel.constants';
import { RecommendedAppointmentInventory } from '@app/registration/enterprise/__generated__/RecommendedAppointmentInventory';
import { AppointmentBookingMultipleSlotsComponent } from '@app/registration/enterprise/appointment-booking-multiple-slots/appointment-booking-multiple-slots.component';
import { EnterpriseRegistration } from '@app/registration/enterprise/enterprise-registration';
import { RecommendedAppointmentInventoryGraphQL } from '@app/registration/enterprise/recommended-appointment-inventory-graphql.service';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { Experiments, PatientExperimentService } from '@app/shared/patient-experiment.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingMultipleSlotsConfig extends RegistrationStep implements OnDestroy {
  readonly MODULE = MODULE_SCHEDULE_VISIT_PAGE;
  readonly progress = 100;
  readonly component = AppointmentBookingMultipleSlotsComponent;
  private readonly destroy$ = new Subject<void>();
  form: FormGroup;

  constructor(
    private recommendedInventoryGraphQL: RecommendedAppointmentInventoryGraphQL,
    private router: Router,
    private patientExperimentService: PatientExperimentService,
    private links: LinksService,
  ) {
    super();
  }

  initComponent(component: AppointmentBookingMultipleSlotsComponent, state: EnterpriseRegistration): void {
    this.recommendedInventoryGraphQL
      .watch({
        recommendRemoteResults: true,
        serviceAreaId: `${state.serviceArea.id}`,
      })
      .valueChanges.subscribe((result: ApolloQueryResult<RecommendedAppointmentInventory>) => {
        if (get(result, 'data.patient.recommendedAppointmentInventory')) {
          this.initAppointmentBookingMultipleSlots(component, result, state);
        } else {
          this.router.navigateByUrl(this.links.home);
        }
      }, takeUntil(this.destroy$));

    component.conciergeStepActive$ = this.patientExperimentService.isVariantEnabled$(
      Experiments.CONCIERGE_EXPERIMENT_NAME,
      Experiments.CONCIERGE_EXPERIMENT_VARIANT_NAME,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    state.setCurrentStep(StepName.concierge);
    return observableOf(true);
  }

  private initAppointmentBookingMultipleSlots(
    component: AppointmentBookingMultipleSlotsComponent,
    result: ApolloQueryResult<RecommendedAppointmentInventory>,
    state: EnterpriseRegistration,
  ) {
    // the content of this method will change as I expand the available appointment booking slots to be 5 days--will refactor with the next story
    component.remoteRecommendation = result.data.patient.recommendedAppointmentInventory;
    const appointmentInventories = component.remoteRecommendation.appointmentInventories.map(inv =>
      AppointmentInventory.fromGraphQL(inv),
    );

    component.appointmentType = AppointmentType.fromGraphQL(component.remoteRecommendation.appointmentType);
    component.dayInventories = groupBy(appointmentInventories, 'date');
    component.patientPreferredName = state.patient.preferredName || state.patient.firstName;
    component.serviceAreaName = state.serviceArea.name;
  }
}
