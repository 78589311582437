import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-covid-vaccination-next-steps',
  templateUrl: './covid-vaccination-next-steps.html',
  styleUrls: ['./covid-vaccination-next-steps.scss'],
})
export class CovidVaccinationNextSteps {
  @Input() isPediatric = false;
  @Input() newAppointmentLink: string;
  @Output() trackBookVisitLink = new EventEmitter();
  // note: volatile requirements have made the collapsible section currently not required, however I'll keep it in
  // for lowering cost of future experiments. Default to `false` and add an anchor to call `expandDetails()` as needed.
  detailsExpanded = true;

  expandDetails() {
    this.detailsExpanded = true;
  }

  newAppointmentClicked($event) {
    $event.preventDefault();
    this.trackBookVisitLink.emit();
  }
}
