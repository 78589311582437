<div class="row m-0 py-lg-3" attr.data-cy="{{ dataCy }}">
  <div *ngIf="iconUrl" class="align-self-center px-0 mx-lg-3">
    <img class="icon" [src]="iconUrl" />
  </div>

  <div class="col align-self-center text-container pl-0 pr-3">
    <omgui-badge *ngIf="badgeText" [text]="badgeText"></omgui-badge>
    <p class="lead mb-0 mt-0 header" data-cy="routing-option-header">
      <strong>{{ header }}</strong>
    </p>
    <div *ngIf="subheader" class="subtitle" data-cy="routing-option-subheader">{{ subheader }}</div>
    <span *ngIf="description" class="text-muted description" data-cy="routing-option-description">
      {{ description }}
    </span>
  </div>
</div>
