import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '@app/app.reducer';
import { AppointmentBookingState } from '@app/appointment/appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from '@app/appointment/appointment-search-service/appointment-search-state';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MixpanelService } from '@app/core/mixpanel.service';

import { AnalyticsService, ANALYTICS_TIMESTAMP_FORMAT } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnalyticsBaseService extends AnalyticsService {
  protected bookingState: AppointmentBookingState;
  protected searchState: AppointmentSearchState;

  constructor(mixpanel: MixpanelService, store: Store<State>, launchDarkly: LaunchDarklyService) {
    super(mixpanel, store, launchDarkly);
  }

  setBookingState(bookingState: AppointmentBookingState) {
    this.bookingState = bookingState;
  }

  setSearchState(searchState: AppointmentSearchState) {
    this.searchState = searchState;
  }

  bookingCancelled() {
    throw new Error('implemented in subclass');
  }

  rescheduled() {
    throw new Error('implemented in subclass');
  }

  bookAppointmentClicked(inventory: AppointmentInventory) {
    throw new Error('implemented in subclass');
  }

  appointmentBooked(appointmentId: number, inventoryId: number, appointmentTypeId?: number) {
    throw new Error('implemented in subclass');
  }

  bookingFailed() {
    throw new Error('implemented in subclass');
  }

  inventorySelected(inventory: AppointmentInventory, props?: any) {
    throw new Error('Implemented in subclass');
  }

  seeMoreAppointmentsClicked(inventoryCount: number, props: any) {
    throw new Error('Implemented in subclass');
  }

  trackWithBookingAndSearchStates(eventName: string, properties?: { [index: string]: any }): Observable<any> {
    const trackProps = {
      ...properties,
      ...this.bookingState?.trackableProperties,
      ...this.searchState?.trackableProperties,
    };

    return this.trackWithDefaultProperties(eventName, trackProps);
  }
}
