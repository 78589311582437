import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LDFlagChangeset } from 'launchdarkly-js-sdk-common';
import mapValues from 'lodash-es/mapValues';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';
import {
  loadFeatureFlag,
  setFlags,
  updateUserWithCustomAttributes,
} from '@app/core/feature-flags/feature-flag.actions';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { UserService } from '@app/core/user.service';
import { registerPaymentInfoSuccess } from '@app/registration/consumer/consumer-registration.actions';

@Injectable()
export class FeatureFlagEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private launchDarklyService: LaunchDarklyService,
    private userService: UserService,
  ) {}

  /**
   * When changes are made in the LaunchDarkly UI, we can react to the updates in real time
   */
  listenForOnChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(action =>
        this.launchDarklyService.onChange$.pipe(
          map((flags: LDFlagChangeset) =>
            setFlags({
              flags: mapValues(flags, 'current'),
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * When we select a given feature flag, if it is not already in the store, we get the value directly from LauchDarkly's client
   * and then save it in the store.
   */
  setFeatureFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatureFlag),
      mergeMap(action =>
        this.launchDarklyService
          .featureFlag$(action.flag, action.defaultValue)
          .pipe(map(value => setFlags({ flags: { [action.flag]: value } }))),
      ),
    ),
  );

  /**
   * Identify the user soon as they login
   */
  updateLaunchDarklyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLoggedIn, registerPaymentInfoSuccess),
        tap(() => this.userService.getUser()),
        switchMap(() => this.userService.user$),
        tap(user => {
          this.launchDarklyService.updateUser({ user });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Identify the user with custom attributes
   */
  updateLaunchDarklyUserWithCustomAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserWithCustomAttributes),
        tap(action => {
          this.userService.user$.subscribe(user => {
            this.launchDarklyService.updateUser({ user, customAttributes: action.customAttributes });
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Set the user back to anonymous when they log out
   */
  resetLaunchDarklyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLoggedOut),
        tap(user => {
          this.launchDarklyService.resetUser();
        }),
      ),
    { dispatch: false },
  );
}
