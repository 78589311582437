import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { Provider } from '@app/shared/provider';
import { ServiceArea } from '@app/shared/service-area';

import { BookableAppointmentTypes } from './__generated__/BookableAppointmentTypes';
import { Appointment } from './appointment';
import { AppointmentBookingState } from './appointment-booking-state-service/appointment-booking-state';
import { AppointmentCancellationReason } from './appointment-cancellation-reason';
import { AppointmentType } from './appointment-type';
import { BookableAppointmentTypesGraphQL } from './bookable-appointment-types-graphql.service';
import { AppointmentInventory } from './provider-inventories';
import { ProviderType, ProviderTypeUtil } from './provider-type';

@Injectable()
export class AppointmentService {
  private _visitReasonCategories = new BehaviorSubject([]);
  readonly visitReasonCategories$ = this._visitReasonCategories.asObservable();

  private _specialtyAppointmentsAvailability = new BehaviorSubject<boolean>(false);
  specialtyAppointmentsAvailability$ = this._specialtyAppointmentsAvailability.asObservable();

  constructor(
    private apiService: ApiService,
    private bookableAppointmentTypesGraphQL: BookableAppointmentTypesGraphQL,
  ) {}

  getCancellationReasons(): Observable<AppointmentCancellationReason[]> {
    return this.apiService
      .get('/api/v2/patient/appointment_cancellation_reasons')
      .pipe(
        map((results: any) =>
          results.map(appointmentCancellationReasons =>
            AppointmentCancellationReason.fromApiV2(appointmentCancellationReasons),
          ),
        ),
      );
  }

  getAppointmentTypes(
    providerType?: ProviderType,
    provider?: Provider,
    serviceArea?: ServiceArea,
  ): Observable<AppointmentType[]> {
    const queryParams = {};
    if (providerType != null) {
      if (provider) {
        queryParams['providerId'] = provider.id;
      }
      if (providerType !== null && providerType !== ProviderType.specificProvider) {
        queryParams['appointmentCategory'] = ProviderTypeUtil.getTypeCategory(providerType);
      }
    }
    if (serviceArea != null) {
      queryParams['serviceAreaId'] = serviceArea.id;
    }

    return this.bookableAppointmentTypesGraphQL
      .fetch(queryParams, { fetchPolicy: 'network-only' })
      .pipe(
        map((result: ApolloQueryResult<BookableAppointmentTypes>) =>
          result.data.patient.bookableAppointmentTypes.map(apptType => AppointmentType.fromGraphQL(apptType)),
        ),
      );
  }

  getSpecialtyAppointmentAvailability(serviceArea: ServiceArea) {
    const queryParams = {
      appointmentCategory: 'specialty',
      serviceAreaId: serviceArea.id,
    };
    this.bookableAppointmentTypesGraphQL
      .fetch(queryParams, { fetchPolicy: 'network-only' })
      .pipe(
        map(
          (result: ApolloQueryResult<BookableAppointmentTypes>) =>
            result.data.patient.bookableAppointmentTypes.length !== 0,
        ),
      )
      .subscribe(specialtyAvailability => this._specialtyAppointmentsAvailability.next(specialtyAvailability));
  }

  saveBookingState(appointmentBookingState: AppointmentBookingState) {
    return this.apiService.post('/api/v2/patient/appointments/search', appointmentBookingState.toApiV2());
  }

  resetBookingState() {
    return this.apiService.post('/api/v2/patient/appointments/reset_cache', {});
  }

  bookAppointment(
    appointmentBookingState: AppointmentBookingState,
    appointmentInventory: AppointmentInventory,
  ): Observable<any> {
    const bookingParams = appointmentBookingState.bookingParams;
    bookingParams.appointment['inventory_id'] = appointmentInventory.id;
    const pathPrefix = '/api/v2/patient/appointments';
    const fullPath = !!appointmentBookingState.getFromAppointmentId() ? `${pathPrefix}/reschedule` : pathPrefix;

    return this.apiService.post(fullPath, bookingParams, false);
  }

  getAppointmentType(id): Observable<AppointmentType> {
    return this.apiService
      .get(`/api/v2/patient/appointment_types/${id}`)
      .pipe(map(resp => AppointmentType.fromApiV2(resp)));
  }

  getAppointment(id): Observable<Appointment> {
    return this.apiService
      .get(`/api/v2/patient/appointments/${id}`)
      .pipe(map(appointmentResponse => Appointment.fromApiV2(appointmentResponse)));
  }
}
