import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { LinksService } from '@app/core/links.service';
import { EnterpriseRoutingService } from '@app/registration/enterprise/enterprise-routing-service';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { MembershipType } from '../membership-type';
import { StepName } from '../registration-step-name';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'direct-signup-step',
  templateUrl: 'direct-signup-step.component.html',
})
export class DirectSignupStepComponent extends RegistrationStepDirective implements OnInit {
  @Output() nextStepSelected = new EventEmitter<StepName>();
  @Input() module: string;
  @Input() isWhitelisted: boolean;

  constructor(
    private links: LinksService,
    private router: Router,
    private analytics: EnterpriseRegistrationAnalyticsService,
    private enterpriseRoutingService: EnterpriseRoutingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.analytics.directSignupSelectionPageViewed();
  }

  typeSelected(type: MembershipType) {
    if (type === MembershipType.SPOUSE) {
      this.goToAdultDirectSignupStep();
    } else {
      this.router.navigate([this.links.registrationPediatric], { queryParams: { fromEnterpriseRegistration: true } });
    }
  }

  goToNextStep() {
    this.enterpriseRoutingService.determineNextStep$().subscribe({
      next: (step: StepName) => (step ? this.goToStep(step) : this.redirectToHomePage()),
    });
  }

  private goToAdultDirectSignupStep() {
    this.nextStepSelected.emit(StepName.adultDirectSignup);
    this.submit.emit();
  }

  redirectToHomePage() {
    this.analytics.redirectToHomeClicked({ module: this.module, isWhitelist: this.isWhitelisted });
    this.router.navigateByUrl(this.links.home);
  }

  goToStep(step: StepName) {
    this.nextStepSelected.emit(step);
    this.submit.emit();
  }
}
