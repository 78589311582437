import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Rollbar from 'rollbar';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { ConfigService } from '@app/core/config.service';
import { LinksService } from '@app/core/links.service';
import { LoginService } from '@app/core/login.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { RollbarService } from '@app/core/rollbar.service';
import { WindowService } from '@app/core/window.service';

@Component({
  selector: 'om-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  static ENTER_KEY_CODE = 13;

  @ViewChild('userNameInput', { static: true }) userNameInput: NgModel;
  @ViewChild('passwordInput', { static: true }) passwordInput: NgModel;
  @ViewChild('passwordElement', { static: true }) passwordElement: ElementRef;

  @Input() signInText: string = null;

  processing: boolean;
  formError = '';
  username = '';
  password = '';

  private returnUrlData$: Observable<{ path: string; isMyonePath: boolean }>;

  constructor(
    private route: ActivatedRoute,
    private attemptedPathService: AttemptedPathService,
    private router: Router,
    public config: ConfigService,
    public links: LinksService,
    private loginService: LoginService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private membershipService: MembershipService,
    private windowService: WindowService,
  ) {}

  ngOnInit() {
    this.processing = false;
    this.returnUrlData$ = this.route.queryParams.pipe(
      map(params => {
        const path = params.returnUrl || params.myone_path;
        const isMyonePath = !!params.myone_path;
        return { path, isMyonePath };
      }),
      shareReplay(1),
    );
    this.returnUrlData$.subscribe(({ path, isMyonePath }) => {
      this.attemptedPathService.setAttemptedPath(path, isMyonePath);
    });
  }

  login(): Observable<[Membership, { path: string; isMyonePath: boolean }]> {
    // iOS Chrome autofill does not emit the events that ngModel depends on, so we must update the value before submitting.
    this.password = this.passwordElement.nativeElement.value;
    this.processing = true;
    const handleLogin$ = this.loginService
      .login(this.username, this.password)
      .pipe(switchMap(() => combineLatest([this.membershipService.getMembershipWithRequest(), this.returnUrlData$])));

    handleLogin$.subscribe({
      next: ([membership, { path, isMyonePath }]: [Membership, { path: string; isMyonePath: boolean }]) => {
        this.redirectAfterLogin(membership, path);
      },
      error: response => {
        if (response.status === 400) {
          if (response.error) {
            // TODO: Use translation file
            if (response.error.error === 'invalid_resource_owner' || response.error.error === 'invalid_grant') {
              this.formError = 'Username or password is incorrect';
            } else if (response.error.error) {
              this.formError = response.error.error;
            } else {
              this.rollbar.warning(`400 unhandled error: ${response}`);
              this.formError = 'We are having some difficulties at the moment.';
            }
          }
        } else {
          this.rollbar.error(`${response.status} error: ${response.error}`);
          this.formError = 'We are having some difficulties at the moment.';
        }
        this.processing = false;
      },
    });

    this.formError = '';
    this.userNameInput.control.markAsTouched();
    this.passwordInput.control.markAsTouched();
    return handleLogin$;
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.keyCode === LoginFormComponent.ENTER_KEY_CODE) {
      this.login();
    }
  }

  private redirectAfterLogin(membership, returnUrl: string) {
    // don't need to look at anything else if they are limited access
    if (membership.hasLimitedAccess()) {
      return this.windowService.redirect(`${this.config.json.myoneServer}/pt/guest`);
    }

    if (this.expiredNeedsRenewal(membership, returnUrl)) {
      return this.renewalRedirect(returnUrl);
    }
    this.attemptedPathService.navigateToAttemptedPath();
  }

  private expiredNeedsRenewal(membership, url): boolean {
    const currentUrl = new URL(url, window.location.href);
    const isGiftRedemption = currentUrl.searchParams.get('gift_code') ? true : false;
    const membershipExpired = membership.isExpired();
    return membershipExpired && !isGiftRedemption;
  }

  private renewalRedirect(returnUrl) {
    let renewalPath = '/membership/renew';
    let queryParams = '';

    if (typeof returnUrl === 'string') {
      queryParams = returnUrl.split('?')[1];
    }

    if (queryParams && queryParams.length > 0) {
      renewalPath = renewalPath + '?' + queryParams;
    }
    this.attemptedPathService.deleteAttemptedPath();
    this.router.navigateByUrl(renewalPath, { replaceUrl: true });
  }
}
