<div class="post-reg-value-props my-4 mx-auto">
  <div>
    <img src="/assets/images/coronavirus.png" />
    <div class="value-prop">
      <div>COVID-19 vaccination is only available to eligible people as defined by state and local authorities.</div>
      <div *ngIf="!detailsExpanded" class="expandable-anchor my-3" (click)="expandDetails()"
        >Click to see how to get your COVID-19 vaccination</div
      >
      <div class="expandable-content my-3" [ngClass]="detailsExpanded ? 'expanded' : 'collapsed'">
        <ul class="trial-vaccination-copy">
          <ng-container *ngIf="!isPediatric; else pediatricSteps">
            <li class="post-reg-actions">
              Click
              <a
                data-cy="register-confirmation-new-appointment-link"
                [routerLink]="newAppointmentLink"
                (click)="newAppointmentClicked($event)"
                >here</a
              >
              to book your COVID-19 Vaccine appointment or the green button below
            </li>
          </ng-container>

          <li class="post-reg-actions">
            Enter “vaccine” as reason for visit, choose “any provider”, choose “COVID-19 Vaccine”, and choose
            appointment time
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <img src="/assets/images/calendar.png" />
    <div class="value-prop">In-person or remote appointments that start on time</div>
  </div>
  <div>
    <img src="/assets/images/mobile-device.png" />
    <div class="value-prop">Unlimited 24/7 Video Chats with our virtual medical team at no extra cost</div>
  </div>
  <div>
    <img src="/assets/images/office.png" />
    <div class="value-prop">90+ convenient locations with drop-in lab services</div>
  </div>
  <div>
    <img src="/assets/images/medications.png" />
    <div class="value-prop">Easy appointment booking and Rx requests in the app</div>
  </div>
</div>

<ng-template #pediatricSteps>
  <li class="post-reg-actions"> Login using your child&apos;s credentials </li>
  <li class="post-reg-actions"> Click “Get Care” to book your COVID-19 Vaccine appointment. </li>
</ng-template>
