import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import Rollbar, { Configuration } from 'rollbar';

import { environment } from '../../environments/environment';

const rollbarConfig: Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  payload: {
    environment: environment.rollbarEnvironment,
    server: {
      root: 'webpack:///./', // https://docs.rollbar.com/docs/source-control#serverroot
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    const error = err.originalError || err;
    if (environment.rollbarAccessToken) {
      this.rollbar.error(error);
    } else {
      console.error(error);
    }
  }
}
