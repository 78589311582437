import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FeatureFlagActions } from '@app/core/feature-flags/feature-flag.actions';
import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>('featureFlags');

export const getFeatureFlag = createSelector(
  selectFeatureFlagState,
  (state: FeatureFlagState, props) => state.flags[props.flag],
);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSelectors {
  constructor(private store: Store<FeatureFlagState>, private featureFlagActions: FeatureFlagActions) {}

  getFeatureFlag<T>(flag: string, defaultValue?: T): Observable<T> {
    return this.store.select(getFeatureFlag, { flag }).pipe(
      tap(() => this.featureFlagActions.loadFeatureFlag(flag, defaultValue)),
      filter(v => v !== undefined),
    );
  }
}
