import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { MODULE_EMAIL_VERIFICATION_PAGE } from '@app/core/mixpanel.constants';
import { EnterpriseRoutingService } from '@app/registration/enterprise/enterprise-routing-service';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { ChildRegistrationAction, RegistrationAnalyticsService } from '../../registration-analytics.service';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-pediatric-direct-signup-success-step',
  templateUrl: './pediatric-direct-signup-success-step.component.html',
})
export class PediatricDirectSignupSuccessStepComponent extends RegistrationStepDirective implements OnInit {
  @Input() patientEmail: string;
  @Input() module: string;
  @Input() isWhitelisted: boolean;
  @Output() nextStepSelected = new EventEmitter<StepName>();

  private readonly membership: Observable<Membership>;

  constructor(
    private links: LinksService,
    private analytics: RegistrationAnalyticsService,
    private router: Router,
    private membershipService: MembershipService,
    private enterpriseAnalytics: EnterpriseRegistrationAnalyticsService,
    private enterpriseRoutingService: EnterpriseRoutingService,
  ) {
    super();
    this.membership = this.membershipService.getMembership().pipe(take(1));
  }

  trackAddAnotherChildClicked = () =>
    this.membership.pipe(
      map(({ omMembershipType }) =>
        this.analytics.confirmationActionSelected({
          action: ChildRegistrationAction.addAnother,
          isLoggedIn: true,
          omMembershipType,
        }),
      ),
    );

  ngOnInit(): void {
    this.membership.subscribe({
      next: ({ omMembershipType }) =>
        this.analytics.confirmationPageViewed({
          isLoggedIn: true,
          omMembershipType,
          referrer: MODULE_EMAIL_VERIFICATION_PAGE,
        }),
    });
  }

  goToNextStep() {
    this.enterpriseRoutingService.determineNextStep$().subscribe({
      next: (step: StepName) => (step ? this.goToStep(step) : this.redirectToHomePage()),
    });
  }

  redirectToHomePage() {
    this.enterpriseAnalytics.redirectToHomeClicked({ module: this.module, isWhitelist: this.isWhitelisted });
    this.router.navigateByUrl(this.links.home);
  }

  goToStep(step: StepName) {
    this.nextStepSelected.emit(step);
    this.submit.emit();
  }
}
