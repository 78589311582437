import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core';
import moment from 'moment';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { MODULE_DOB_SEX_PAGE } from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { minimumAge } from '@app/shared/date-validator.directive';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';

import { WhitelistedEmployeeDependent } from '../__generated__/WhitelistedEmployeeDependent';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { WhitelistedEmployeeDependentGraphqlService } from '../whitelisted-employee-dependent-graphql.service';
import { DependentAlreadyRegisteredError, NoWhitelistedDependentFoundError } from '../whitelisted-employee-errors';
import { DobSexStepComponent } from './dob-sex-step.component';

@Injectable()
export class DobSexConfig extends RegistrationStep {
  GA_LABEL = 'Tell_More_About_Step';

  MODULE = MODULE_DOB_SEX_PAGE;
  component = DobSexStepComponent;
  progress = 40;
  showLoginLink = true;

  MINIMUM_AGE = 14;

  form: FormGroup = this.formBuilder.group({
    dob: this.formBuilder.control({ date: '' }, { validators: [minimumAge(this.MINIMUM_AGE)] }),
    genderDetails: '',
    sex: ['', Validators.required],
  });

  constructor(
    public serviceAreaService: ServiceAreaService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private formBuilder: FormBuilder,
    private whitelistDependentGraphQLService: WhitelistedEmployeeDependentGraphqlService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    super();
  }

  initComponent(component: DobSexStepComponent) {
    component.form = this.form;
    component.minimumAge = this.MINIMUM_AGE;
    this.trackPageView();
  }

  onDestroy() {
    this.form.controls.dob.clearValidators();
  }

  patchParams(params: { [k: string]: any }) {
    const { dob, gender } = params;
    if (dob) {
      this.form.patchValue({ dob: { date: dob } });
    }
    if (gender) {
      this.form.patchValue({ sex: gender });
    }
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (this.form.valid) {
      state.patient.gender = this.form.controls.sex.value;
      state.patient.genderDetails = this.form.controls.genderDetails.value;
      state.patient.dob = this.form.controls.dob.value['date'];
      state.patient.ageInYears = moment().diff(state.patient.dob, 'years');
      if (state.isWhitelisted && state.isAdultDependent && state.verifyDependentInfo()) {
        return captcha.getToken().pipe(
          switchMap((reCaptchaToken: string) =>
            this.whitelistDependentGraphQLService.fetch(state.dependentQueryParams(reCaptchaToken)),
          ),
          switchMap((result: ApolloQueryResult<WhitelistedEmployeeDependent>) => {
            const { whitelistedEmployeeDependent } = result.data;
            if (whitelistedEmployeeDependent === null) {
              return observableThrowError(new NoWhitelistedDependentFoundError());
            }
            if (whitelistedEmployeeDependent.registered) {
              return observableThrowError(new DependentAlreadyRegisteredError());
            }
            state.setCurrentStep(StepName.serviceArea);
            this.trackSubmission(state);
            return observableOf(true);
          }),
          catchError(err => observableThrowError(err)),
        );
      }

      if (this.authService.isLoggedIn() && state.userIsComplete()) {
        return state.submitAccountConversion(this.userService, captcha, state.b2bCompany.includesDependent);
      }

      const b2bCompanyId = state.details.b2bCompanyId;

      // This works for 99% of our use case for OM Now customers. We should refactor this
      // to have combineLatest with the above observable to include whitelisted and adult dependent
      // patients skipping the service area screen.
      this.serviceAreaService.getServiceAreas(b2bCompanyId);
      return this.serviceAreaService.serviceAreas$.pipe(
        map((serviceAreas: ServiceArea[]) => {
          if (serviceAreas.length === 1) {
            state.setServiceArea(serviceAreas[0]);
            state.setCurrentStep(StepName.createAccount);
          } else {
            state.setCurrentStep(StepName.serviceArea);
          }
          this.trackSubmission(state);
          return true;
        }),
      );
    }
    return observableThrowError(new Error());
  }

  private trackSubmission(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      isWhitelist: state.isWhitelisted,
      module: this.MODULE,
    });
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }
}
