import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-camera',
  templateUrl: './svg-camera.component.html',
})
export class SvgCameraComponent implements OnInit {
  @Input() color = '#49AB94';
  @Input() width = 29;
  @Input() height = 29;
  @Input() size: 'default' | 'extra-extra-large' = 'default';

  ngOnInit() {
    if (this.size === 'extra-extra-large') {
      this.width = 60;
      this.height = 60;
    }
  }
}
