import { Injectable } from '@angular/core';

import { CookieStorage } from '../shared/storage';

export interface StorageDuration {
  infinite?: boolean;
  duration?: Date;
}

@Injectable()
export class StorageService {
  storage: CookieStorage;

  constructor() {
    this.storage = new CookieStorage();
  }

  getItem(key) {
    return this.storage.getItem(key);
  }

  setItem(key, value, storageDuration?: StorageDuration) {
    this.storage.setItem(key, value, storageDuration);
  }

  setTldItem(key, value) {
    this.storage.setTldItem(key, value);
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  removeTldItem(key) {
    this.storage.removeTldItem(key);
  }
}
