import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MODULE_COMPANY_CODE_PAGE } from '@app/core/mixpanel.constants';
import { EnterpriseRegistrationAnalyticsService } from '@app/registration/enterprise/enterprise-registration-analytics.service';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { InvalidConversionError } from '@app/registration/enterprise/whitelisted-employee-errors';

import { AuthService } from '../../../core/auth.service';
import { UserService } from '../../../core/user.service';
import {
  ActivationCodeService,
  ValidateActivationCodes,
  ValidateActivationCodesB2bCompany,
} from '../activation-code.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { MembershipType } from '../membership-type';
import { ActivationCodeStepComponent } from './activation-code-step.component';

@Injectable()
export class ActivationCodeConfig extends RegistrationStep {
  GA_LABEL = 'Activation_Code_Step';

  MODULE = MODULE_COMPANY_CODE_PAGE;
  captchaNeeded = true;
  component = ActivationCodeStepComponent;
  progress = 10;
  showLoginLink = true;

  form: FormGroup = this.formBuilder.group({
    activationCode: '',
  });

  constructor(
    private codeService: ActivationCodeService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
    super();
  }

  initComponent(component: ActivationCodeStepComponent, state: EnterpriseRegistration) {
    component.form = this.form;
    component.codeRetrievalEmail = state.workEmail;
    component.canEmailCode = state.canRetrieveCodeViaEmail;
    component.resendCode.subscribe(email => {
      this.enterpriseRegistrationAnalyticsService.sendCompanyCodeViewed();
      this.codeService.resendCode(email).subscribe(
        () => {
          component.codeSent = true;
        },
        () => {
          component.resendError = true;
        },
      );
    });
    this.trackPageView();
  }

  patchParams(params: { [k: string]: any }) {
    if (params.discount_code) {
      this.form.patchValue({ activationCode: params.discount_code });
      this.shouldFastForward = true;
    }
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    this.shouldFastForward = false;
    this.trackSubmission();
    state.details.activationCode = this.form.value.activationCode;
    return captcha.getToken().pipe(
      switchMap((reCaptchaToken: string) =>
        this.codeService.validate(state.activationCode, reCaptchaToken).pipe(
          switchMap((response: ValidateActivationCodes) => {
            const b2bCompany = response.b2b_company;
            this.setB2bCompany(state, b2bCompany);
            if (!state.whitelistedEmployee || state.whitelistedEmployee.b2bCompany.id !== b2bCompany.id.toString()) {
              // Remove any leftover employee details because they conflict with the data entered here
              state.details.employeeId = null;
              state.details.whitelistedEmployeeId = null;
            }
            state.details.b2bCompanyId = +b2bCompany.id;
            state.patchParams({ b2bCompanyId: b2bCompany.id });

            if (this.authService.isLoggedIn() && state.userIsComplete()) {
              return state.submitAccountConversion(this.userService, captcha, b2bCompany.includes_dependent);
            } else {
              return this.submitNewAccount(state, b2bCompany);
            }
          }),
        ),
      ),
      catchError((err: Error) => {
        if (err.message === 'enterpriseConversionError') {
          this.form.controls.activationCode.setErrors({ system: true });
        } else if (err.message === 'planDoesntSupportDependents') {
          this.form.controls.activationCode.setErrors({ planDoesntSupportDependents: true });
        } else if ((err as InvalidConversionError).invalidConversion) {
          this.form.controls.activationCode.setErrors({ invalidConversion: true });
        } else {
          this.form.controls.activationCode.setErrors({ invalid: true });
        }

        this.trackSubmissionError(err.message);
        throw new InvalidActivationCodeError();
      }),
    );
  }

  private setB2bCompany(state: EnterpriseRegistration, b2bCompany: ValidateActivationCodesB2bCompany) {
    state.b2bCompany = {
      __typename: null,
      id: b2bCompany.id.toString(),
      b2bEmailDomains: b2bCompany.b2b_email_domains,
      includesDependent: b2bCompany.includes_dependent,
      verifyDependentInfo: b2bCompany.verify_dependent_info,
      activationCodeRetrievableViaEmail: false,
    };
  }

  private submitNewAccount(
    state: EnterpriseRegistration,
    b2bCompany: ValidateActivationCodesB2bCompany,
  ): Observable<any> {
    if (this.intendedNextStep) {
      state.setCurrentStep(this.intendedNextStep);
      return observableOf(true);
    }

    if (b2bCompany.includes_dependent) {
      state.setCurrentStep(StepName.membershipSelection);
    } else {
      state.patchParams({ membershipType: MembershipType.PERSONAL });
      state.setCurrentStep(StepName.accountSetUp);
    }

    return observableOf(true);
  }

  private trackSubmissionError(message: string) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error: message,
      formField: 'Discount Code',
      isWhitelist: false,
      module: this.MODULE,
    });
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }

  private trackSubmission() {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      isWhitelist: false,
      module: this.MODULE,
      companyCode: this.form.value.activationCode,
    });
  }
}

class InvalidActivationCodeError extends Error {}
