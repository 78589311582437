import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { UserService } from '@app/core/user.service';

export enum Experiments {
  // Proactive Care Experiments
  CONCIERGE_EXPERIMENT_NAME = 'PC Concierge',
  CONCIERGE_EXPERIMENT_VARIANT_NAME = 'Variant: Show Concierge',
  APPOINTMENT_BOOKING_EXPERIMENT_NAME = 'PC Show More Appointment Slots',
  APPOINTMENT_BOOKING_EXPERIMENT_VARIANT_NAME = 'Variant: Show more booking slots',
  CONCIERGE_PROVIDER_ORDER_EXPERIMENT_NAME = 'PC Choose your provider order',
  CONCIERGE_PROVIDER_ORDER_EXPERIMENT_VARIANT_NAME = 'Variant: Second option',

  // BHX Experiments
  POST_REGISTRATION_RECOMMENDATIONS_EXPERIMENT_NAME = 'BHX Post-Registration Recommendations',
  POST_REGISTRATION_RECOMMENDATIONS_EXPERIMENT_VARIANT_NAME = 'Variant: Show Post-Registration Recommendations',
  INLINE_SURVEY_EXPERIMENT_NAME = 'Inline Survey',
  INLINE_SURVEY_VARIANT_NAME = 'BHX Homescreen Inline Survey',
}

@Injectable()
export class PatientExperimentService {
  constructor(private apiService: ApiService, private userService: UserService) {}

  isVariantEnabled$(experimentName, variantName): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap(user =>
        this.apiService.get('/api/v2/patient/patient_experiment', false, {
          patient_id: user.id,
          experiment_name: experimentName,
        }),
      ),
      map((variantNameJson: any) => variantNameJson && variantNameJson['experimentVariant'] === variantName),
    );
  }
}
