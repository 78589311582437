import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { Params } from '@angular/router';

import { ComponentType } from '@app/component-type';

export interface Link {
  routerLink?: string;
  name: string;
  svg?: ComponentType;
  subRoutes?: Link[];
  queryParams?: Params;
}

@Component({
  selector: 'om-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterViewInit {
  @ViewChildren('svg', { read: ViewContainerRef }) svgComponents: QueryList<ViewContainerRef>;

  @Input() sidebarTitle: string;
  @Input() links: Link[];
  @Input() matchExact = true;

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {}

  ngAfterViewInit() {
    this.initSvgs();
  }

  initSvgs() {
    this.svgComponents.forEach((svgComponent: ViewContainerRef, index: number) => {
      const component = this.componentFactoryResolver.resolveComponentFactory(this.links[index].svg);

      svgComponent.clear();

      const componentRef = svgComponent.createComponent(component);
      componentRef.changeDetectorRef.detectChanges();
    });
  }
}
