import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';

export enum FeatureFlagActionTypes {
  LD_CLIENT_INITIALIZED = '[Feature Flag] LD Client Initialized',
  SET_FLAGS = '[Feature Flag] Set Flags',
  LOAD_FEATURE_FLAG = '[Feature Flag] Load Feature Flag',
  UPDATE_USER_WITH_CUSTOM_ATTRIBUTES = '[Feature Flag] Update LD User with custom attributes',
}

export const setFlags = createAction(FeatureFlagActionTypes.SET_FLAGS, props<{ flags: any }>());
export const loadFeatureFlag = createAction(
  FeatureFlagActionTypes.LOAD_FEATURE_FLAG,
  props<{ flag: string; defaultValue: any }>(),
);
export const updateUserWithCustomAttributes = createAction(
  FeatureFlagActionTypes.UPDATE_USER_WITH_CUSTOM_ATTRIBUTES,
  props<{ customAttributes: any }>(),
);

@Injectable({ providedIn: 'root' })
export class FeatureFlagActions {
  constructor(private store: Store<FeatureFlagState>) {}

  setFlags(flags: LDFlagSet) {
    this.store.dispatch(setFlags({ flags }));
  }

  loadFeatureFlag<T>(flag: string, defaultValue?: T) {
    this.store.dispatch(loadFeatureFlag({ flag, defaultValue: defaultValue || false }));
  }

  updateUserWithCustomAttributes(customAttributes) {
    this.store.dispatch(updateUserWithCustomAttributes(customAttributes));
  }
}
