import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { MODULE_CONCIERGE_PAGE } from '@app/core/mixpanel.constants';
import { EnterpriseRegistration } from '@app/registration/enterprise/enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '@app/registration/enterprise/enterprise-registration-analytics.service';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';

import { ConciergeStepComponent } from './concierge-step.component';

@Injectable({
  providedIn: 'root',
})
export class ConciergeConfig extends RegistrationStep {
  readonly MODULE = MODULE_CONCIERGE_PAGE;
  readonly component = ConciergeStepComponent;
  readonly progress = 100;
  readonly form: FormGroup;
  private newPcpSelection = false;

  constructor(private featureFlagSelectors: FeatureFlagSelectors) {
    super();
  }

  initComponent(component: ConciergeStepComponent, state: EnterpriseRegistration): void {
    component.healthIntakeSurveyId = state.patient.healthHistorySurveyId();
    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.NEW_PCP_SELECTION, false)
      .subscribe(on => (this.newPcpSelection = on));
  }

  trackPageVisit(state: EnterpriseRegistration, analytics: EnterpriseRegistrationAnalyticsService) {
    if (!this.newPcpSelection) {
      analytics.pageViewed({
        module: this.MODULE,
        isWhitelist: state.isWhitelisted,
        source: state.getPreviousStep().MODULE,
      });
    }
  }

  submit(state: EnterpriseRegistration): Observable<unknown> {
    return undefined;
  }
}
