import { Injectable } from '@angular/core';
import moment from 'moment';
import { take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { Features } from '@app/shared';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { AppointmentBookingState } from '../appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from './appointment-search-state';

@Injectable()
export class AppointmentSearchStateService {
  numDisplayDays: number;

  private searchState: AppointmentSearchState;

  constructor(
    private analyticsService: AppointmentAnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  initSearchState(bookingState: AppointmentBookingState, numDisplayDays: number) {
    const searchState = new AppointmentSearchState(bookingState);
    searchState.dateStart = moment();
    this.searchState = searchState;
    this.analyticsService.setSearchState(searchState);
    this.setNumDisplayDays(numDisplayDays);
  }

  setNumDisplayDays(numDisplayDays: number): void {
    this.numDisplayDays = numDisplayDays;
    this.searchState.dateEnd = this.searchState.dateStart.clone().add(this.dayDiff, 'days');
  }

  getSearchState(): AppointmentSearchState {
    return this.searchState;
  }

  setSearchStateStart(date: moment.Moment) {
    this.searchState.dateStart = date;
  }

  setSearchDateEnd(date: moment.Moment) {
    this.searchState.dateEnd = date;
  }

  previousPage(): void {
    let newDateEnd: moment.Moment = this.searchState.dateEnd.clone().subtract(this.numDisplayDays, 'days');
    let newDateStart: moment.Moment = newDateEnd.clone().subtract(this.dayDiff, 'days');
    if (newDateStart.isBefore(moment())) {
      newDateStart = moment();
      newDateEnd = moment().add(this.dayDiff, 'days');
    }

    this.searchState.dateStart = newDateStart;
    this.searchState.dateEnd = newDateEnd;
  }

  nextPage(): void {
    const newDateStart = this.searchState.dateStart.clone().add(this.numDisplayDays, 'days');
    this.searchState.dateStart = newDateStart.clone();
    this.searchState.dateEnd = newDateStart.add(this.dayDiff, 'days');
  }

  private get dayDiff(): number {
    return this.numDisplayDays - 1;
  }
}
