/* Mixpanel Event Names */
export const MP_EVENT_PAGE_VIEWED = 'Page Viewed';

/* Registration Specific MP Constants. Used by Consumer, Pediatric, and B2b Reg */
export const MP_EVENT_REG_INPUT_SUBMITTED = 'Registration Input Submitted';
export const MP_EVENT_REG_ADD_DISCOUNT_CODE_CLICKED = 'Add Discount Code Clicked';
export const MP_EVENT_REG_DISCOUNT_CODE_APPLIED = 'Discount Code Applied';
export const MP_EVENT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Registration Successfully Submitted';
export const MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Gift Registration Successfully Submitted';
export const MP_EVENT_INVALID_CC_ENTERED = 'Invalid Credit Card Information Added';
export const MP_EVENT_PROMO_CODE_AUTO_APPLIED = 'Promotion Code Auto Applied';
export const MP_EVENT_REG_CONFIRMATION_SURVEY_CLICKED = 'Health History Survey Started';
export const MP_EVENT_REG_CONFIRMATION_APPOINTMENT_CLICKED = 'Book Appointment Clicked';
export const MODULE_BILLING = 'Billing Page';
export const MODULE_PEDIATRIC_TERMS_OF_SERVICE = 'Peds Terms of Service page';

/* Enterprise Registration */
export const MODULE_DIRECT_SIGN_UP_SELECTION_PAGE = 'Direct Sign Up Selection Page';
export const MODULE_DIRECT_SIGN_UP_FAMILY_PAGE = 'Direct Sign Up Family Page';
export const MODULE_EMAIL_VERIFICATION_PAGE = 'Email Verification Page';
export const MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE = 'Enterprise Registration Confirmation Page';
export const MODULE_CONCIERGE_PAGE = 'Concierge Page';
export const MODULE_ACCOUNT_SETUP_PAGE = 'Account Setup Page';
export const MODULE_COMPANY_CODE_PAGE = 'Company Code Page';
export const MODULE_SCHEDULE_VISIT_PAGE = 'Schedule Visit Page';
export const MODULE_ACCOUNT_CREATION_PAGE = 'Account Creation Page';
export const MODULE_DOB_SEX_PAGE = 'DOB Sex Page';
export const MODULE_EID_VALIDATION_PAGE = 'EID Validation Page';
export const MODULE_CHOOSE_RELATIONSHIP_TYPE_PAGE = 'Choose Relationship Type Page';
export const MODULE_SERVICE_AREA_PAGE = 'Service Area Page';
export const MODULE_LEGAL_DOCUMENT_PAGE = 'Legal Document Page';
export const MODULE_WORK_EMAIL_VALIDATION_PAGE = 'Work Email Validation Page';
export const MODULE_CHILD_MEMBERSHIP_CONFIRMATION_PAGE = 'Child Membership Confirmation Page';
export const MODULE_PCP_OVERVIEW = 'PCP Overview Page';
export const MODULE_PCP_SELECTION_PAGE = 'PCP Selection Page';
export const MODULE_PCP_SELECTED_MODAL_PAGE = 'PCP Selected Modal Page';

/* Enterprise Post-Registration Appointment Recommendation Experiment */
export const ISSUE_REFINEMENT_PAGE = 'Issue Refinement Page';
export const ISSUE_SELECTION_PAGE = 'Issue Selection Page';

/* Membership Settings */
export const MODULE_SETTINGS_PAGE = 'MyOne Membership Settings Page';

/* Email Verification */
export const MP_EVENT_EMAIL_VERIFIED = 'Email Successfully Verified';
export const MP_EVENT_EMAIL_UNVERIFIED = 'Email Unsuccessfully Verified';
export const MP_EVENT_EMAIL_VERIFICATION_ACTION = 'Email Verification Action Selected';
export const MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED = 'Account Locked';
export const MP_EVENT_EMAIL_VERIFICATION_SENT = 'Verification Email Sent';
export const MP_EVENT_EDIT_EMAIL = 'Edit Email Clicked';
export const MP_EVENT_EDIT_EMAIL_ACTION = 'Edit Email Action Selected';
export const MP_EVENT_ADD_PREFERRED_EMAIL = 'Add Preferred Email Clicked';
export const MP_EVENT_ADD_PREFERRED_EMAIL_ACTION = 'Add Preferred Email Action Selected';
export const SUBMODULE_CODE_MODAL = 'Email Verification Modal';

export enum MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE {
  CONTINUE = 'Continue',
  EMAIL = 'techsupport@onemedical.com',
  SKIP = 'Skip for now',
  RESEND = 'Resend email',
}

export const MODULE_HOME_PAGE = 'MyOne Home Page';
export const SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD = 'Email Verification Onboarding Carousel Card';
export const EMAIL_VERIFICATION_OUTREACH_EMAIL = 'Email Verification Outreach Email';

/* Legacy Provider Team Page aka PCP Overview Page */
export const MODULE_PCP_OVERVIEW_PAGE = 'PCP Overview Page';

/* Messages */
export const MODULE_MESSAGES_OVERVIEW_PAGE = 'Messages Overview Page';

/* Flows */
export const FLOW_ACCOUNT_UPDATE = 'Account Update';
export const FLOW_HOME_PAGE_NAVIGATION = 'Home Page Navigation';
export const FLOW_LOGIN = 'Login';

/* Modals */
export const PRESCRIPTION_TREAT_ME_NOW_MODAL = 'Prescription TMN';
export const EVENT_TREAT_ME_NOW_MODAL_CLOSED = 'TMN Modal Closed';

/* Login */
export const LOGIN_STARTED = 'Login Started';
