import { Injectable } from '@angular/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { StepName } from '@app/registration/enterprise/registration-step-name';
import { FeatureFlagService, Features } from '@app/shared';
import { Experiments, PatientExperimentService } from '@app/shared/patient-experiment.service';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseRoutingService {
  constructor(
    private patientExperimentService: PatientExperimentService,
    private featureFlagService: FeatureFlagService,
  ) {}

  determineNextStep$(): Observable<StepName> {
    return combineLatest([
      this.featureFlagService.featureActive$(Features.PROACTIVE_CARE_ENABLE_POST_REGISTRATION_BOOKING),
      this.patientExperimentService.isVariantEnabled$(
        Experiments.POST_REGISTRATION_RECOMMENDATIONS_EXPERIMENT_NAME,
        Experiments.POST_REGISTRATION_RECOMMENDATIONS_EXPERIMENT_VARIANT_NAME,
      ),
      this.patientExperimentService.isVariantEnabled$(
        Experiments.CONCIERGE_EXPERIMENT_NAME,
        Experiments.CONCIERGE_EXPERIMENT_VARIANT_NAME,
      ),
      this.patientExperimentService.isVariantEnabled$(
        Experiments.APPOINTMENT_BOOKING_EXPERIMENT_NAME,
        Experiments.APPOINTMENT_BOOKING_EXPERIMENT_VARIANT_NAME,
      ),
    ]).pipe(
      map(([bookingActive, recommendationActive, conciergeActive, appointmentBookingActive]) => {
        if (recommendationActive) {
          return StepName.appointmentRecommendation;
        } else if (bookingActive) {
          return appointmentBookingActive ? StepName.appointmentBookingMultipleSlots : StepName.appointmentBooking;
        } else if (conciergeActive) {
          return StepName.concierge;
        }
      }),
      catchError(() => observableOf(undefined)),
    );
  }
}
