import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import get from 'lodash-es/get';

@Injectable()
export class NativeAppService {
  isWebView: boolean;
  hideFooter: boolean;

  shouldHideNavigation(route: ActivatedRoute): boolean {
    if (this.isWebView === undefined) {
      this.isWebView = route.snapshot.queryParams.is_web_view === 'true';
    }
    return this.isWebView;
  }

  shouldHideFooter(route: ActivatedRoute): boolean {
    if (this.hideFooter === undefined) {
      this.hideFooter = route.snapshot.queryParams.hide_footer === 'true';
    }
    return this.hideFooter;
  }

  postMessageToWebkitWebView(message) {
    if (get(window, ['webkit', 'messageHandlers', 'callbackHandler', 'postMessage'])) {
      (<any>window).webkit.messageHandlers.callbackHandler.postMessage(message);
    }
  }

  invokeAndroidWindowMethod(method: string) {
    if (get(window, ['Android', method])) {
      (<any>window).Android[method]();
    }
  }
}
