import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { AnalyticsService } from '@app/core/analytics.service';
import { ConfigService } from '@app/core/config.service';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipService } from '@app/core/membership.service';
import {
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_EMAIL_VERIFIED,
  MP_EVENT_EMAIL_UNVERIFIED,
  MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
  MP_EVENT_EMAIL_VERIFICATION_ACTION,
  MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE,
  MODULE_BILLING,
  MODULE_EMAIL_VERIFICATION_PAGE,
  MODULE_PEDIATRIC_TERMS_OF_SERVICE,
  MODULE_SERVICE_AREA_PAGE,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { Coupon } from '@app/shared/coupon';
import { ServiceArea } from '@app/shared/service-area';

import { environment } from '../../environments/environment';

export interface RegInputEnteredProperties {
  formField: string;
  membershipType: string;
  module: string;
  value: any;
}

declare const ga;
declare const fbq;
declare const window;

export enum ChildRegistrationAction {
  addAnother = 'Add Another',
  returnHome = 'Return Home',
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationAnalyticsService extends AnalyticsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private membershipCoupon$: Observable<Coupon>;
  private isFamilyFlow = false;

  constructor(
    private config: ConfigService,
    protected membershipService: MembershipService,
    store: Store<State>,
    mixpanel: MixpanelService,
    launchDarklyService: LaunchDarklyService,
  ) {
    super(mixpanel, store, launchDarklyService);

    if (config.json.gaAccount) {
      ga('create', config.json.gaAccount, 'auto');
      ga('send', 'pageview', location.pathname);
    }

    if (config.json.facebookAccount) {
      fbq('init', config.json.facebookAccount);
      fbq('track', 'PageView');

      fbq.push(['addPixelId', config.json.facebookAccount]);
      fbq.push(['track', 'PixelInitialized', {}]);
    }

    this.membershipCoupon$ = this.membershipService.membership$.pipe(
      map(membership => (membership.isActive ? membership.promotionDiscountCoupon : null)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.membershipCoupon$.pipe(takeUntil(this.destroy$)).subscribe();

    if (config.json.linkedinPartnerId) {
      (<any>window)._linkedin_data_partner_id = config.json.linkedinPartnerId;
      (function () {
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode.insertBefore(b, s);
      })();
    }

    if (config.json.hotjarId) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: config.json.hotjarId, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(<any>window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(props: any) {
    return this.trackWithDefaultProperties(props.eventName, {
      ...props,
    });
  }

  trackPaymentAttempt(paymentMethod: string) {
    return this.trackWithDefaultProperties('Payment Attempt', {
      paymentMethod,
      flow: 'Registration',
      module: MODULE_BILLING,
    });
  }

  trackWithMembershipCoupon(eventName: string, inputProperties = {}) {
    this.membershipCoupon$
      .pipe(
        map(coupon => {
          const properties = { ...inputProperties };
          // potentially add coupon details to mixpanel
          return properties;
        }),
        take(1),
      )
      .subscribe({
        next: properties => this.trackWithDefaultProperties(eventName, properties),
      });
  }

  regInputEntered(props: RegInputEnteredProperties) {
    ga('send', 'event', 'reg-question-answered', props.formField);

    return this.track('Registration Input Entered', {
      flow: 'Registration',
      form_field: props.formField,
      module: props.module,
      om_membership_type: props.membershipType,
      value: props.value || null,
    });
  }

  invalidRegInputEntered(question: string) {
    ga('send', 'event', 'reg-question-answered-invalid', question);
  }

  previousQuestionClicked(currentQuestion: string) {
    ga('send', 'event', 'reg-back-button-clicked', currentQuestion);
  }

  trackGoogleEvent(category: string, action: string, label: string) {
    ga('send', 'event', category, action, label);
  }

  childIntakePageViewed({ isLoggedIn, source }: { isLoggedIn: boolean; source?: string }) {
    this.trackGoogleEvent('PedSignupForm', 'page view', 'ChildInfo_Page');

    let source_property = '';
    const module_variant = source === 'familyFlowConfirmationPage' ? 'Me and My Child' : '';
    if (['confirmationPagePromoCard', 'familyFlowConfirmationPage'].indexOf(source) !== -1) {
      source_property = 'Adult Registration Confirmation Page';
      this.isFamilyFlow = true; // needed for reporting module_variant on the confirmation page
    }

    this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      module_variant: module_variant,
      source: source_property,
    });
  }

  childBirthTypeSelected({
    unborn,
    isLoggedIn,
    omMembershipType,
  }: {
    unborn: boolean;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Child Birth Type Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      birth_type: unborn ? 'Unborn' : 'Born',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  guardianConfirmationPageViewed({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  guardianConfirmationPageConfirmButtonClicked({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Confirmed', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  guardianConfirmationPageClosed({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Go Back', {
      flow: 'Direct Sign Up',
      module: 'Guardian Changes Modal',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  childAccountCreationPageViewed({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  childAccountEmailLinkSelected({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Child Account Email Link Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  pedsUnavailableModalShown({
    serviceArea,
    isLoggedIn,
    omMembershipType,
  }: {
    serviceArea: ServiceArea;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Pediatrics Location Error Modal',
      service_area: serviceArea.name,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  confirmationPageViewed({
    isLoggedIn,
    omMembershipType,
    referrer,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    referrer: string;
  }) {
    const module_variant = this.isFamilyFlow ? 'Another Kid OM Kids Special 2021' : '';
    this.trackGoogleEvent('PedSignupForm', 'page view', 'Confirmation_Page');
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      module_variant: module_variant,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      source: referrer,
    });
  }

  confirmationActionSelected({
    action,
    isLoggedIn,
    omMembershipType,
  }: {
    action: ChildRegistrationAction;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Child Membership Confirmation Action Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      action_selected: action,
    });
  }

  addressManualLinkClicked({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Address Manual Link Clicked', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  tosPageViewed({
    is_pediatrics,
    isLoggedIn,
    omMembershipType,
  }: {
    is_pediatrics: boolean;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Peds TOS page',
      is_pediatrics: is_pediatrics,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  legalDocumentSigned({
    isLoggedIn,
    isPediatrics,
    omMembershipType,
  }: {
    isLoggedIn: boolean;
    isPediatrics: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Legal Document Signed', {
      flow: 'Direct Sign Up',
      module: MODULE_PEDIATRIC_TERMS_OF_SERVICE,
      is_pediatrics: isPediatrics,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  existingEmailError({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Existing Email Error', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  serviceAreaPageViewed({
    isLoggedIn,
    omMembershipType,
    submodule,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    submodule: string;
  }) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: MODULE_SERVICE_AREA_PAGE,
      is_whitelist: false,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      submodule: submodule,
    });
  }

  serviceAreaSelected({
    isLoggedIn,
    serviceArea,
    isPedsServiceable,
    omMembershipType,
  }: {
    isLoggedIn: boolean;
    serviceArea: ServiceArea;
    isPedsServiceable: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Service Area Selected', {
      flow: 'Registration',
      module: MODULE_SERVICE_AREA_PAGE,
      is_whitelist: false,
      is_logged_in: isLoggedIn,
      service_area: serviceArea.name,
      is_peds_serviceable: isPedsServiceable,
      om_membership_type: omMembershipType,
    });
  }

  paymentPageViewed({ membershipType, isLoggedIn }: { membershipType: string; isLoggedIn: boolean }) {
    const properties = {
      flow: 'Registration',
      module: MODULE_BILLING,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
    };
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, properties);
  }

  paymentSubmitted({ membershipType, isLoggedIn }: { membershipType: string; isLoggedIn: boolean }) {
    this.membershipCoupon$
      .pipe(
        map(coupon => {
          const properties = {
            flow: 'Registration',
            module: MODULE_BILLING,
            om_membership_type: membershipType,
            is_logged_in: isLoggedIn,
            is_whitelist: false,
            is_pediatrics: true,
          };

          if (coupon) {
            return {
              ...properties,
              promo_code: coupon.id,
              promo_code_amount: coupon.amountOff,
              promo_code_percent: coupon.percentOff,
              promo_code_type: coupon.discountType,
            };
          } else {
            return properties;
          }
        }),
        take(1),
      )
      .subscribe({
        next: properties => this.trackWithMembershipCoupon('Billing Info Submitted', properties),
      });
  }

  paymentSubmitError({
    membershipType,
    isLoggedIn,
    error,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    error: string;
  }) {
    return this.trackWithDefaultProperties('Submit Error Encountered', {
      flow: 'Registration',
      module: MODULE_BILLING,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      error_message: error,
    });
  }

  emailVerificationPageViewed(membershipType: string, serviceArea: ServiceArea) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: membershipType,
      service_area: serviceArea.name,
      is_pediatrics: true,
    };

    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, properties);
  }

  emailVerificationSuccess(membershipType: string, serviceArea: ServiceArea) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: membershipType,
      service_area: serviceArea.name,
      is_pediatrics: true,
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFIED, properties);
  }

  emailVerificationUnverified(membershipType: string, serviceArea: ServiceArea) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: membershipType,
      service_area: serviceArea.name,
      is_pediatrics: true,
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_UNVERIFIED, properties);
  }

  emailVerificationLockedOut(membershipType: string, serviceArea: ServiceArea) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: membershipType,
      service_area: serviceArea.name,
      is_pediatrics: true,
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED, properties);
  }

  emailVerificationAction(
    membershipType: string,
    serviceArea: ServiceArea,
    action: MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE,
    accountLocked: boolean,
  ) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: membershipType,
      service_area: serviceArea.name,
      is_pediatrics: true,
      action_selected: action,
    };

    if (accountLocked) {
      properties.module_variant = 'Account Locked Error State Page';
    }

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_ACTION, properties);
  }

  familyTypePageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: 'Family Type Page',
    });
  }

  familyTypeSelectedAction(action: string) {
    return this.trackWithDefaultProperties('Family type selected', {
      flow: 'Registration',
      module: 'Family Type Page',
      action_selected: action,
    });
  }

  planTypePageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: 'Plan Type Page',
    });
  }

  planTypeSelectedAction(action: string) {
    return this.trackWithDefaultProperties('Plan type selected', {
      flow: 'Registration',
      module: 'Plan Type Page',
      action_selected: action,
    });
  }

  freeTrialCodeSubmitted(code: string) {
    return this.trackWithDefaultProperties('Free Trial Code Applied', {
      flow: 'Registration',
      module: 'Free Trial Code Page',
      form_field: 'Free Trial Code',
      is_trial: true,
      value: code,
    });
  }

  /**
   * Ported over from old consumer reg in 1life
   * @param eventName
   */
  notifyFacebookTracker(eventName: string) {
    fbq('track', eventName);
  }

  /**
   * Ported over from the old consumer reg in 1life
   */
  notifyAdwordsPage2Conversion() {
    const googleSnippetVars = function () {
      const w = window;
      w.google_conversion_id = environment.googleConversionId;
      w.google_conversion_language = 'en';
      w.google_conversion_format = '3';
      w.google_conversion_color = 'ffffff';
      w.google_conversion_label = 'FfzNCID_8l8Ql_fa7wM';
      w.google_remarketing_only = false;
    };
    // DO NOT CHANGE THE CODE BELOW.
    const reportConversion = function (url?) {
      googleSnippetVars();
      const opt = new Object();
      opt['onload_callback'] = function () {
        if (typeof url != 'undefined') {
          window.location = url;
        }
      };
      const conversionHandler = window['google_trackConversion'];
      if (typeof conversionHandler == 'function') {
        conversionHandler(opt);
      }
    };
    reportConversion();
  }

  directSignupAccountCompletion({ module, event, source }: { module: string; event?: string; source?: string }) {
    return this.trackWithDefaultProperties(event || MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: module,
      om_membership_type: 'Enterprise',
      source: source,
    });
  }
}
